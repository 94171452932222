import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

interface Config {
  donationsVersion: number;
  usersVersion: number;
  cacheTimeout: number;
}

interface ConfigContextType {
  config: Config;
  isLoading: boolean;
  error: string | null;
  refreshConfig: () => Promise<void>;
}

const DEFAULT_CONFIG: Config = {
  donationsVersion: 1,
  usersVersion: 1,
  cacheTimeout: 60 * 60 * 1000, // 1 hour in milliseconds
};

const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [config, setConfig] = useState<Config>(DEFAULT_CONFIG);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchConfig = async () => {
    try {
      const configDoc = await getDoc(doc(db, 'config', 'global'));
      if (configDoc.exists()) {
        const fetchedConfig = configDoc.data() as Config;
        setConfig({
          donationsVersion: fetchedConfig.donationsVersion || DEFAULT_CONFIG.donationsVersion,
          usersVersion: fetchedConfig.usersVersion || DEFAULT_CONFIG.usersVersion,
          cacheTimeout: fetchedConfig.cacheTimeout || DEFAULT_CONFIG.cacheTimeout,
        });
      }
    } catch (err) {
      console.error('Error fetching config:', err);
      setError('Using default configuration');
    } finally {
      setIsLoading(false);
    }
  };

  const refreshConfig = async () => {
    setIsLoading(true);
    await fetchConfig();
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  return (
    <ConfigContext.Provider value={{ config, isLoading, error, refreshConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};