import React, { useState, useEffect } from 'react';
import { Trophy, Clock, Info } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import InformationModal from './InformationModal';
import { useAuth } from '../context/AuthContext';
import { useDonations } from '../context/DonationsContext';
import { useLocation } from 'react-router-dom';

export const DonationsTable: React.FC = () => {
  const [showTopDonations, setShowTopDonations] = useState(true);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showAll, setShowAll] = useState(() => {
    // Recuperar el estado de localStorage al iniciar
    const savedState = localStorage.getItem('donationsTableExpanded');
    return savedState === 'true';
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const { isAdmin } = useAuth();
  const {
    topDonations,
    recentDonations,
    isLoading,
    error,
    isRefreshing,
    loadFullData,
    hasFullData,
  } = useDonations();
  const location = useLocation();
  const isDonatePage = location.pathname === '/donar';

  // Efecto para restablecer showAll cuando la caché se reinicia
  useEffect(() => {
    if (!hasFullData) {
      setShowAll(false);
      localStorage.removeItem('donationsTableExpanded');
    }
  }, [hasFullData]);

  const INITIAL_DISPLAY_COUNT = 3;
  const MAX_DISPLAY_COUNT = 20;

  const displayedDonations = showTopDonations
    ? topDonations.slice(0, showAll ? MAX_DISPLAY_COUNT : INITIAL_DISPLAY_COUNT)
    : recentDonations.slice(
        0,
        showAll ? MAX_DISPLAY_COUNT : INITIAL_DISPLAY_COUNT
      );

  const hasMoreToShow = isDonatePage && !showAll;

  const handleShowMore = async () => {
    if (!hasFullData) {
      await loadFullData();
    }
    setShowAll(true);
    // Guardar el estado en localStorage
    localStorage.setItem('donationsTableExpanded', 'true');
  };

  const handleToggleView = () => {
    setShowTopDonations(!showTopDonations);
    // Al cambiar de vista, mantener el estado expandido si estaba activo
    if (!showAll) {
      setShowAll(false);
      localStorage.setItem('donationsTableExpanded', 'false');
    }
  };

  // Restablecer el estado cuando se sale de la página de donaciones
  useEffect(() => {
    if (!isDonatePage) {
      setShowAll(false);
      localStorage.setItem('donationsTableExpanded', 'false');
    }
  }, [isDonatePage]);

  const getTrophyColor = (index: number) => {
    switch (index) {
      case 0:
        return 'text-yellow-500';
      case 1:
        return 'text-gray-400';
      case 2:
        return 'text-orange-500';
      default:
        return 'text-gray-400';
    }
  };

  const getTimeAgo = (date: string) => {
    const now = new Date();
    const donationDate = new Date(date);
    const diffInSeconds = Math.floor(
      (now.getTime() - donationDate.getTime()) / 1000
    );

    const minutes = Math.floor(diffInSeconds / 60);
    const hours = Math.floor(diffInSeconds / 3600);
    const days = Math.floor(diffInSeconds / 86400);
    const weeks = Math.floor(diffInSeconds / (86400 * 7));
    const months = Math.floor(diffInSeconds / (86400 * 30));
    const years = Math.floor(diffInSeconds / (86400 * 365));

    if (minutes < 60) {
      return `fa ${minutes} ${minutes === 1 ? 'minut' : 'minuts'}`;
    }
    if (hours < 24) {
      return `fa ${hours} ${hours === 1 ? 'hora' : 'hores'}`;
    }
    if (days < 7) {
      return `fa ${days} ${days === 1 ? 'dia' : 'dies'}`;
    }
    if (weeks < 4) {
      return `fa ${weeks} ${weeks === 1 ? 'setmana' : 'setmanes'}`;
    }
    if (months < 12) {
      return `fa ${months} ${months === 1 ? 'mes' : 'mesos'}`;
    }
    return `fa ${years} ${years === 1 ? 'any' : 'anys'}`;
  };

  const getRowStyle = (index: number) => {
    if (!showTopDonations) {
      return index % 2 === 0
        ? 'bg-gray-50 dark:bg-gray-800 text-gray-800 dark:text-white'
        : 'bg-white dark:bg-gray-700 text-gray-800 dark:text-white';
    }

    switch (index) {
      case 0:
        return 'bg-gradient-to-r from-yellow-200 to-yellow-100 dark:from-yellow-900 dark:to-yellow-800 font-bold text-black dark:text-white';
      case 1:
        return 'bg-gradient-to-r from-gray-200 to-gray-100 dark:from-gray-600 dark:to-gray-500 font-bold text-black dark:text-white';
      case 2:
        return 'bg-gradient-to-r from-orange-200 to-orange-100 dark:from-orange-900 dark:to-orange-800 font-bold text-black dark:text-white';
      default:
        return index % 2 === 0
          ? 'bg-gray-50 dark:bg-gray-800 text-gray-800 dark:text-white'
          : 'bg-white dark:bg-gray-700 text-gray-800 dark:text-white';
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden p-8">
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-500"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden p-8">
        <div className="text-center text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-800 dark:text-white">
          {showTopDonations ? 'Top Donadors' : 'Últimes Donacions'}
        </h2>
        <div className="flex items-center space-x-2 sm:space-x-4">
          <button
            onClick={handleToggleView}
            className="flex items-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-white rounded-full px-3 sm:px-4 py-1.5 sm:py-2 transition-all duration-300 text-xs sm:text-sm transform hover:scale-105"
          >
            {showTopDonations ? (
              <>
                <Clock size={16} className={isMobile ? '' : 'mr-1'} />
                {!isMobile && 'Veure Últimes'}
              </>
            ) : (
              <>
                <Trophy size={16} className={isMobile ? '' : 'mr-1'} />
                {!isMobile && 'Veure Top'}
              </>
            )}
          </button>
          <button
            onClick={() => setShowInfoModal(true)}
            className="flex items-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-white rounded-full px-2 sm:px-3 py-1.5 sm:py-2 transition-all duration-300 transform hover:scale-105"
            aria-label="Informació"
          >
            <Info className="w-4 h-4 sm:w-5 sm:h-5" />
          </button>
        </div>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={showTopDonations ? 'top' : 'recent'}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden relative"
        >
          <table className="w-full">
            <thead>
              <tr className="bg-gradient-to-r from-yellow-500 to-red-500 text-white">
                {showTopDonations ? (
                  <>
                    <th className="py-3 px-4 text-left w-1/4">Posició</th>
                    <th className="py-3 px-4 text-center w-1/2">Nom</th>
                    <th className="py-3 px-4 text-right w-1/4">Donat</th>
                  </>
                ) : (
                  <>
                    <th className="py-3 px-4 text-left w-1/4">Temps</th>
                    <th className="py-3 px-4 text-center w-1/2">Nom</th>
                    <th className="py-3 px-4 text-right w-1/4">Donat</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              <AnimatePresence>
                {displayedDonations.map((donation, index) => (
                  <motion.tr
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                    className={`${getRowStyle(
                      index
                    )} transition-all duration-300 hover:shadow-inner`}
                  >
                    {showTopDonations ? (
                      <>
                        <td className="py-3 px-4 flex items-center">
                          {index < 3 ? (
                            <Trophy
                              className={`${getTrophyColor(index)} mr-2`}
                              size={20}
                            />
                          ) : (
                            <span className="font-semibold">{index + 1}</span>
                          )}
                        </td>
                        <td className="py-3 px-4 font-medium text-center">
                          {donation.name}
                        </td>
                        <td className="py-3 px-4 text-right font-bold">
                          {donation.amount.toFixed(2)}€
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="py-3 px-4 text-sm flex items-center">
                          <Clock className="mr-2" size={16} />
                          {getTimeAgo(donation.date!)}
                        </td>
                        <td className="py-3 px-4 font-medium text-center">
                          {donation.name}
                        </td>
                        <td className="py-3 px-4 text-right font-bold">
                          {donation.amount.toFixed(2)}€
                        </td>
                      </>
                    )}
                  </motion.tr>
                ))}
              </AnimatePresence>
            </tbody>
          </table>

          {hasMoreToShow && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="p-4 text-center"
            >
              <button
                onClick={handleShowMore}
                className="flex items-center mx-auto bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-white rounded-full px-3 sm:px-4 py-1.5 sm:py-2 transition-all duration-300 text-xs sm:text-sm transform hover:scale-105"
              >
                Veure més
              </button>
            </motion.div>
          )}

          {isRefreshing && (
            <div className="absolute inset-0 bg-black bg-opacity-10 dark:bg-opacity-20 flex items-center justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-500"></div>
            </div>
          )}
        </motion.div>
      </AnimatePresence>

      <InformationModal
        isOpen={showInfoModal}
        onClose={() => setShowInfoModal(false)}
      />
    </>
  );
};
