import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import BaseModal from './BaseModal';
import { motion } from 'framer-motion'; // Import motion

interface IdentityModalProps {
  isOpen: boolean;
  onClose: () => void;
  userName: string;
}

const IdentityModal: React.FC<IdentityModalProps> = ({
  isOpen,
  onClose,
  userName,
}) => {
  const [newName, setNewName] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const defaultName = 'El teu nom';
  const MAX_NAME_LENGTH = 20;

  useEffect(() => {
    const storedName = localStorage.getItem('userName');
    if (storedName) {
      setNewName(storedName);
    } else if (userName) {
      setNewName(userName);
    } else {
      setNewName('');
    }
  }, [userName]);

  const handleClose = () => {
    if (localStorage.getItem('userName')) {
      setNewName(localStorage.getItem('userName') || '');
    } else {
      setNewName('');
    }
    setError('');
    setIsSubmitting(false);
    onClose();
  };

  const handleSaveName = async () => {
    setError('');

    if (!newName.trim()) {
      setError('Si us plau, introdueix un nom');
      return;
    }

    if (newName.length > MAX_NAME_LENGTH) {
      setError(`El nom no pot tenir més de ${MAX_NAME_LENGTH} caràcters.`);
      return;
    }

    try {
      setIsSubmitting(true);

      // Save name to localStorage
      localStorage.setItem('userName', newName);

      // Dispatch event to notify name change
      window.dispatchEvent(
        new CustomEvent('userNameChanged', {
          detail: { name: newName },
        })
      );

      handleClose();
      navigate('/usuari');
    } catch (error) {
      console.error('Error guardant el nom:', error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(
          'Error al guardar el nom. Si us plau, intenta-ho de nou més tard.'
        );
      }
      setIsSubmitting(false);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    if (newName.length <= MAX_NAME_LENGTH) {
      setNewName(newName);
    }
  };

  return (
    <BaseModal isOpen={isOpen} onClose={handleClose} maxWidth="sm">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white">
          Nom d'usuari
        </h2>
        <button
          onClick={handleClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <X size={20} />
        </button>
      </div>
      <div className="space-y-2 mb-4 text-sm sm:text-base text-gray-600 dark:text-gray-300">
        <p>Introdueix el teu nom per accedir a la teva pàgina d’usuari.</p>
        <p>
          Pots canviar el teu nom quan ho necessitis i explorar la teva pàgina,
          on trobaràs el teu minijoc preferit, les estadístiques del teu progrés
          i altres opcions per gestionar el teu perfil.
        </p>
        <p>Gràcies per formar part d'aquesta aventura!</p>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSaveName();
        }}
        className="space-y-4"
      >
        <div>
          <input
            type="text"
            value={newName}
            onChange={handleNameChange}
            placeholder={defaultName}
            className="w-full p-3 border-2 border-yellow-500 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
            required
            maxLength={MAX_NAME_LENGTH}
          />
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            {newName.length}/{MAX_NAME_LENGTH} caràcters
          </p>
        </div>

        {error && (
          <p className="text-red-500 text-sm bg-red-100 dark:bg-red-900/30 p-2.5 rounded-lg">
            {error}
          </p>
        )}

        <div className="pt-2">
          <motion.button
            type="submit"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="w-full bg-gradient-to-r from-yellow-500 to-red-500 hover:from-yellow-600 hover:to-red-600 text-white font-bold py-2.5 px-4 rounded-lg transition duration-300 text-base"
          >
            Acceptar
          </motion.button>
        </div>
      </form>
    </BaseModal>
  );
};

export default IdentityModal;
