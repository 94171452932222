import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { useConfig } from './ConfigContext';

interface Donation {
  name: string;
  amount: number;
  date?: string;
}

interface DonationsContextType {
  topDonations: Donation[];
  recentDonations: Donation[];
  totalDonated: number | null;
  isLoading: boolean;
  isRefreshing: boolean;
  error: string | null;
  hasFullData: boolean;
  refreshDonations: () => Promise<void>;
  loadFullData: () => Promise<void>;
}

const DonationsContext = createContext<DonationsContextType | undefined>(undefined);

interface CachedData {
  data: any;
  timestamp: number;
  version: number;
  isFullData: boolean;
  etag?: string;
}

interface UserCache {
  totalDonated: number;
  timestamp: number;
  version: number;
  etag?: string;
}

export const DonationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const { config } = useConfig();
  const [topDonations, setTopDonations] = useState<Donation[]>([]);
  const [recentDonations, setRecentDonations] = useState<Donation[]>([]);
  const [totalDonated, setTotalDonated] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasFullData, setHasFullData] = useState(false);
  const [lastEtags, setLastEtags] = useState<{[key: string]: string}>({});

  const getCachedData = (key: string): CachedData | null => {
  try {
    const cached = localStorage.getItem(key);
    if (!cached) return null;
    
    const parsedCache = JSON.parse(cached);
    const now = Date.now();
    
    if (config && (
      now - parsedCache.timestamp > config.cacheTimeout ||
      parsedCache.version !== config.donationsVersion
    )) {
      localStorage.removeItem(key);
      
      // Limpiar el estado expandido si la caché se reinicia
      if (key === 'topDonations' || key === 'recentDonations') {
        localStorage.removeItem('donationsTableExpanded');
      }
      
      return null;
    }
    
    return parsedCache;
  } catch (error) {
    console.error('Error reading cache:', error);
    return null;
  }
};

  const setCachedData = (key: string, data: any, etag?: string, isFullData: boolean = false) => {
    try {
      if (!config) return;

      const cacheData: CachedData = {
        data,
        timestamp: Date.now(),
        version: config.donationsVersion,
        isFullData,
        etag
      };
      localStorage.setItem(key, JSON.stringify(cacheData));
    } catch (error) {
      console.error('Error setting cache:', error);
    }
  };

  const fetchWithEtag = async (url: string, options: RequestInit = {}) => {
    const etag = lastEtags[url];
    if (etag) {
      options.headers = {
        ...options.headers,
        'If-None-Match': etag
      };
    }

    const response = await fetch(url, options);
    
    if (response.status === 304) {
      return { notModified: true };
    }

    const newEtag = response.headers.get('ETag');
    if (newEtag) {
      setLastEtags(prev => ({...prev, [url]: newEtag}));
    }

    return { 
      notModified: false, 
      data: await response.json(),
      etag: newEtag
    };
  };

  const loadFullData = async () => {
    if (!config || hasFullData) return;

    try {
      setIsRefreshing(true);
      const apiUrl = import.meta.env.VITE_APP_URL;
      
      const [topResponse, recentResponse] = await Promise.all([
        fetchWithEtag(`${apiUrl}/.netlify/functions/get-top-donations`),
        fetchWithEtag(`${apiUrl}/.netlify/functions/get-recent-donations`)
      ]);

      if (!topResponse.notModified) {
        setTopDonations(topResponse.data);
        setCachedData('topDonations', topResponse.data, topResponse.etag, true);
      }

      if (!recentResponse.notModified) {
        setRecentDonations(recentResponse.data);
        setCachedData('recentDonations', recentResponse.data, recentResponse.etag, true);
      }

      setHasFullData(true);
      setError(null);
    } catch (error) {
      console.error('Error fetching full data:', error);
      setError('Error loading full data');
    } finally {
      setIsRefreshing(false);
    }
  };

  const fetchDonations = useCallback(async (force: boolean = false) => {
    if (!config) return;

    const now = Date.now();
    if (!force && hasFullData) return;

    if (!force) {
      const cachedTop = getCachedData('topDonations');
      const cachedRecent = getCachedData('recentDonations');
      
      if (cachedTop && cachedRecent) {
        setTopDonations(cachedTop.data);
        setRecentDonations(cachedRecent.data);
        setHasFullData(cachedTop.isFullData);
        
        if (user) {
          const userCache = getCachedData(`user_${user.id}_donations`);
          if (userCache) {
            setTotalDonated(userCache.data);
            setIsLoading(false);
            return;
          }
        } else {
          setIsLoading(false);
          return;
        }
      }
    }

    setIsLoading(true);
    setError(null);

    try {
      const apiUrl = import.meta.env.VITE_APP_URL;
      const promises = [
        fetchWithEtag(`${apiUrl}/.netlify/functions/get-top-donations?initial=1`),
        fetchWithEtag(`${apiUrl}/.netlify/functions/get-recent-donations?initial=1`)
      ];

      if (user) {
        const token = await user.jwt();
        promises.push(
          fetchWithEtag(`${apiUrl}/.netlify/functions/get-user-donations`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        );
      }

      const responses = await Promise.all(promises);
      
      if (!responses[0].notModified) {
        setTopDonations(responses[0].data);
        setCachedData('topDonations', responses[0].data, responses[0].etag);
      }

      if (!responses[1].notModified) {
        setRecentDonations(responses[1].data);
        setCachedData('recentDonations', responses[1].data, responses[1].etag);
      }

      if (responses[2] && !responses[2].notModified && user) {
        setTotalDonated(responses[2].data.totalDonated);
        setCachedData(`user_${user.id}_donations`, responses[2].data.totalDonated, responses[2].etag);
      }
      
      setError(null);
    } catch (error) {
      console.error('Error fetching donations:', error);
      setError('No s\'han pogut carregar les donacions. Si us plau, prova amb reiniciar la pàgina.');
      
      const cachedTop = getCachedData('topDonations');
      const cachedRecent = getCachedData('recentDonations');
      
      if (cachedTop) {
        setTopDonations(cachedTop.data);
        setHasFullData(cachedTop.isFullData);
      }
      if (cachedRecent) setRecentDonations(cachedRecent.data);
      
      if (user) {
        const userCache = getCachedData(`user_${user.id}_donations`);
        if (userCache) setTotalDonated(userCache.data);
      }
    } finally {
      setIsLoading(false);
    }
  }, [config, user, hasFullData]);

  useEffect(() => {
    if (config) {
      fetchDonations();
      const interval = setInterval(() => fetchDonations(true), config.cacheTimeout);
      return () => clearInterval(interval);
    }
  }, [fetchDonations, config]);

  const refreshDonations = useCallback(() => fetchDonations(true), [fetchDonations]);

  return (
    <DonationsContext.Provider 
      value={{ 
        topDonations, 
        recentDonations,
        totalDonated,
        isLoading,
        isRefreshing,
        error,
        hasFullData,
        refreshDonations,
        loadFullData
      }}
    >
      {children}
    </DonationsContext.Provider>
  );
};

export const useDonations = () => {
  const context = useContext(DonationsContext);
  if (context === undefined) {
    throw new Error('useDonations must be used within a DonationsProvider');
  }
  return context;
};