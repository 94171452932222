// src/utils/gameUtils.ts

export interface GameTag {
  label: string;
  priority: number;
}

export interface GameStat {
  id: number;
  gamesPlayed: number;
  maxRound: number;
}

const NEW_GAME_IDS = [1]; // Ajusta esta lista según tus necesidades
let MOST_POPULAR_GAME_IDS: number[] = [2]; // Ahora editable
export const setMostPopularGames = (ids: number[]) => {
  MOST_POPULAR_GAME_IDS = ids;
};

export const isNewGame = (gameId: number): boolean => {
  return NEW_GAME_IDS.includes(gameId);
};

export const isMostPopular = (gameId: number): boolean => {
  return MOST_POPULAR_GAME_IDS.includes(gameId);
};

export const getGameTags = (
  gameId: number,
  gameStats: GameStat[],
  mostPlayedGameId: number,
  bestRoundGameId: number
): GameTag[] => {
  const tags: GameTag[] = [];
  const isNew = isNewGame(gameId);
  const isMostPlayed = gameId === mostPlayedGameId;
  const isPopular = isMostPopular(gameId);
  const isBestRound = gameId === bestRoundGameId;

  if (isNew) {
    tags.push({ label: 'Nou!', priority: 3 });
  }
  if (isPopular) {
    tags.push({ label: 'Més Popular!', priority: 2 });
  } else if (isMostPlayed) {
    tags.push({ label: 'Més Jugat!', priority: 2 });
  } else if (isBestRound) {
    tags.push({ label: 'Millor Ronda!', priority: 1 });
  }

  return tags;
};

export const sortGames = (
  games: Game[],
  gameStats: GameStat[],
  mostPlayedGameId: number,
  bestRoundGameId: number
): Game[] => {
  return [...games].sort((a, b) => {
    const aPlayed =
      gameStats.find((gs) => gs.id === a.info.id)?.gamesPlayed || 0;
    const bPlayed =
      gameStats.find((gs) => gs.id === b.info.id)?.gamesPlayed || 0;
    const aIsNew = isNewGame(a.info.id);
    const bIsNew = isNewGame(b.info.id);
    const aIsMostPlayed = a.info.id === mostPlayedGameId;
    const bIsMostPlayed = b.info.id === mostPlayedGameId;
    const aIsPopular = isMostPopular(a.info.id);
    const bIsPopular = isMostPopular(b.info.id);
    const aIsBestRound = a.info.id === bestRoundGameId;
    const bIsBestRound = b.info.id === bestRoundGameId;
    const aIsGameOne = a.info.id === 1;
    const bIsGameOne = b.info.id === 1;

    // Nuevos juegos primero
    if (aIsNew && !bIsNew) return -1;
    if (!aIsNew && bIsNew) return 1;

    // Luego por más popular
    if (aIsPopular && !bIsPopular) return -1;
    if (!aIsPopular && bIsPopular) return 1;

    // Luego por más jugados (orden descendente)
    if (bPlayed > aPlayed) return 1;
    if (aPlayed > bPlayed) return -1;

    // Luego por mejor ronda (orden descendente)
    if (bIsBestRound && !aIsBestRound) return 1;
    if (aIsBestRound && !bIsBestRound) return -1;

    // Si ninguno es nuevo ni el más jugado ni el de mejor ronda, priorizar juego 1 si se han jugado menos de 10 veces
    if (aPlayed < 10 && bPlayed < 10) {
      if (aIsGameOne && !bIsGameOne) return -1;
      if (!aIsGameOne && bIsGameOne) return 1;
    }

    // Juego 1 siempre al final
    if (aIsGameOne && !bIsGameOne) return 1;
    if (!aIsGameOne && bIsGameOne) return -1;

    // Finalmente, ordenar por ID
    return a.info.id - b.info.id;
  });
};
