import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { User } from 'lucide-react';
import { TopDonations } from './components/TopDonations';
import DarkModeToggle from './components/DarkModeToggle';
import AdminButton from './components/AdminButton';
import Footer from './components/Footer';
import InstructionsModal from './components/InstructionsModal';
import UpdatesModal from './components/UpdatesModal';
import IdentityModal from './components/IdentityModal';
import { useAuth } from './context/AuthContext';
import { useTheme } from './context/ThemeContext';
import Logo from './components/Logo';
import { useSuppressWarnings } from './utils/suppressWarnings';
import { getGames } from './games/registry';
import { CURRENT_VERSION, VERSIONS } from './appVersions';
import { getGameTags, sortGames, GameTag, GameStat } from './utils/gameUtils';

const App: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { isDarkMode } = useTheme();
  const { user, login } = useAuth();
  const donationsRef = useRef<HTMLDivElement>(null);

  // Estados para controlar modales
  const [showInstructions, setShowInstructions] = useState(false);
  const [showUpdates, setShowUpdates] = useState(false);
  const [showIdentity, setShowIdentity] = useState(false);
  const [currentChanges, setCurrentChanges] = useState<string[]>([]);
  const [currentVersion, setCurrentVersion] = useState<string>('');

  // Estado para mostrar enlace a la página de usuario
  const [showUserPage, setShowUserPage] = useState(false);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const games = getGames();

  useSuppressWarnings();

  const [gameStats, setGameStats] = useState<GameStat[]>([]);

  // Memo para detectar cambios en las estadísticas
  const statsMemo = useMemo(() => {
    return gameStats
      .map((stat) => `${stat.id}-${stat.gamesPlayed}-${stat.maxRound}`)
      .join(',');
  }, [gameStats]);

  useEffect(() => {
    const stats = games.map((game) => {
      const gameStats = game.getStats();
      return {
        id: game.info.id,
        gamesPlayed: gameStats?.gamesPlayed || 0,
        maxRound: gameStats?.maxRound || 0,
      };
    });
    setGameStats(stats);
  }, [games]);

  const mostPlayedGame = useMemo(() => {
    return gameStats.reduce(
      (prev, current) =>
        current.gamesPlayed > prev.gamesPlayed ? current : prev,
      { id: 0, gamesPlayed: 0, maxRound: 0 }
    );
  }, [gameStats]);

  const bestRoundGame = useMemo(() => {
    return gameStats.reduce(
      (prev, current) => (current.maxRound > prev.maxRound ? current : prev),
      { id: 0, gamesPlayed: 0, maxRound: 0 }
    );
  }, [gameStats]);

  // Ordenar juegos utilizando la función centralizada
  const orderedGames = useMemo(() => {
    return sortGames(games, gameStats, mostPlayedGame.id, bestRoundGame.id);
  }, [games, gameStats, mostPlayedGame.id, bestRoundGame.id]);

  // Función para comprobar si algún juego ha sido jugado
  const hasAnyGameBeenPlayed = () => {
    return gameStats.some((game) => game.gamesPlayed > 0);
  };

  useEffect(() => {
    const loadTimeout = setTimeout(() => setIsLoaded(true), 100);
    window.scrollTo(0, 0);

    if (window.location.hash === '#donations' && donationsRef.current) {
      donationsRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');

    if (!hasVisitedBefore) {
      setShowInstructions(true);
      localStorage.setItem('hasVisitedBefore', 'true');
      localStorage.setItem('lastSeenVersion', CURRENT_VERSION);
    }

    const lastSeenVersion = localStorage.getItem('lastSeenVersion');
    const latestVersion = VERSIONS.find((v) => v.version === CURRENT_VERSION);

    if (latestVersion && lastSeenVersion !== latestVersion.version) {
      setCurrentVersion(latestVersion.version);
      setCurrentChanges(latestVersion.changes);
      setShowUpdates(true);
      localStorage.setItem('lastSeenVersion', latestVersion.version);
    }

    // Comprobar si existe userName
    const userName = localStorage.getItem('userName');
    if (userName) {
      setShowUserPage(true);
    }

    const openIdentityModalParam = searchParams.get('ObrirModalUsuari');
    if (openIdentityModalParam === 'true') {
      setShowIdentity(true);
    }

    return () => {
      clearTimeout(loadTimeout);
    };
  }, [searchParams]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key && event.key.startsWith('gameStats')) {
        const updatedStats = games.map((game) => {
          const gameStats = game.getStats();
          return {
            id: game.info.id,
            gamesPlayed: gameStats?.gamesPlayed || 0,
            maxRound: gameStats?.maxRound || 0,
          };
        });
        setGameStats(updatedStats);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [games]);

  return (
    <div className="flex flex-col min-h-screen bg-texture transition-colors duration-300">
      <div className="flex-grow px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
        <div className="max-w-7xl mx-auto">
          <header
            className={`flex justify-between items-center mb-6 sm:mb-8 transition-all duration-300 ${
              isLoaded
                ? 'translate-y-0 opacity-100'
                : '-translate-y-10 opacity-0'
            }`}
          >
            {showUserPage ? (
              <button onClick={() => navigate('/usuari')} className="group">
                <div className="icon-bg">
                  {localStorage.getItem('userName') ? (
                    <span className="text-white text-xl md:text-3xl lg:text-4xl font-bold">
                      {localStorage.getItem('userName')![0].toUpperCase()}
                    </span>
                  ) : (
                    <User className="text-white w-1/2 h-1/2" />
                  )}
                </div>
              </button>
            ) : (
              <button onClick={() => setShowIdentity(true)} className="group">
                <div className="icon-bg">
                  <User className="text-white w-1/2 h-1/2" />
                </div>
              </button>
            )}
            <div className="flex items-center space-x-2">
              <Logo className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 lg:w-14 lg:h-14" />
              <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-red-500 leading-tight">
                minijocs.cat
              </h1>
            </div>
            <div className="flex items-center space-x-2">
              <AdminButton />
              <DarkModeToggle />
            </div>
          </header>
          <main
            className={`mt-4 sm:mt-6 md:mt-8 transition-all duration-300 ${
              isLoaded
                ? 'translate-y-0 opacity-100'
                : 'translate-y-10 opacity-0'
            }`}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 md:gap-8">
              {orderedGames.map((game) => {
                const tags: GameTag[] = getGameTags(
                  game.info.id,
                  gameStats,
                  mostPlayedGame.id,
                  bestRoundGame.id
                );
                return (
                  <Link
                    key={game.info.id}
                    to={`${game.info.path}`}
                    className="group block"
                  >
                    <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden h-full transition-transform duration-300 transform hover:scale-105">
                      <div className="h-24 sm:h-32 md:h-40 bg-gradient-to-r from-yellow-500 to-red-500 flex items-center justify-center relative overflow-hidden">
                        <game.info.icon className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 text-white relative z-10 transform group-hover:scale-110 transition-transform duration-300" />
                      </div>
                      <div className="p-4 sm:p-5">
                        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 dark:text-white group-hover:text-yellow-500 transition-colors duration-300 mb-2 sm:mb-0">
                            {game.info.title}
                          </h3>
                          <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400 sm:text-right">
                            {game.info.description}
                          </p>
                        </div>
                      </div>
                      {/* Etiquetas de juegos */}
                      <div className="absolute top-2 right-2 flex z-20">
                        {tags
                          .sort((a, b) => b.priority - a.priority)
                          .map((tag, index) => (
                            <div
                              key={index}
                              className={`text-sm px-3 py-1 rounded-md ml-1 font-medium ${
                                isDarkMode
                                  ? 'bg-gray-700 text-white'
                                  : 'bg-gray-200 text-gray-800'
                              }`}
                            >
                              {tag.label}
                            </div>
                          ))}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
            <div ref={donationsRef} id="donations" className="mt-8">
              <Link to="/donar">
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-xl group">
                  <div className="p-4 sm:p-6">
                    <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 text-gray-800 dark:text-white transition-colors duration-300 flex items-center justify-center group-hover:text-yellow-500">
                      Top Donadors
                    </h2>
                    <TopDonations limit={3} />
                  </div>
                </div>
              </Link>
            </div>
          </main>
        </div>
      </div>
      <Footer />
      <InstructionsModal
        isOpen={showInstructions}
        onClose={() => setShowInstructions(false)}
      />
      <UpdatesModal
        isOpen={showUpdates}
        onClose={() => setShowUpdates(false)}
        version={currentVersion}
        changes={currentChanges}
      />
      <IdentityModal
        isOpen={showIdentity}
        onClose={() => setShowIdentity(false)}
        userName={localStorage.getItem('userName') || ''}
      />
      {/* Botón oculto */}
      <div className="absolute bottom-0 right-0 p-2">
        <button
          id="hidden-netlify-identity"
          onClick={login}
          className="w-2 h-2 opacity-0 hover"
        ></button>
      </div>
    </div>
  );
};

export default App;
