// src/UserPage.tsx

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  ArrowLeft,
  User,
  LogOut,
  Calendar,
  Edit,
  Check,
  X,
  Repeat,
  Gamepad2,
  List,
} from 'lucide-react';
import DarkModeToggle from './components/DarkModeToggle';
import Footer from './components/Footer';
import { TopDonations } from './components/TopDonations';
import { motion } from 'framer-motion';
import LogoutModal from './components/LogoutModal';
import PasswordChangeModal from './components/PasswordChangeModal';
import { getGames, getGame } from './games/registry';
import GameCard from './components/GameCard';
import StatsTransferModal from './components/StatsTransferModal';
import { GameStat, GameTag, getGameTags, sortGames } from './utils/gameUtils';
import { useTheme } from './context/ThemeContext';

const UserPage: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState<string>('');
  const [editingName, setEditingName] = useState('');
  const [accountCreationDate, setAccountCreationDate] = useState<Date | null>(
    null
  );
  const [totalGamesPlayed, setTotalGamesPlayed] = useState(0);
  const [favoriteGame, setFavoriteGame] = useState<{
    title: string;
    id: number;
  }>({ title: '', id: 1 });
  const [nameError, setNameError] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showStatsTransferModal, setShowStatsTransferModal] = useState(false);
  const navigate = useNavigate();
  const games = getGames();
  const { isDarkMode } = useTheme();

  const MAX_NAME_LENGTH = 20;

  const [gameStats, setGameStats] = useState<GameStat[]>(() =>
    games.map((game) => ({
      id: game.info.id,
      gamesPlayed: game.getStats()?.gamesPlayed || 0,
      maxRound: game.getStats()?.maxRound || 0,
    }))
  );

  const mostPlayedGame = useMemo(() => {
    return gameStats.reduce(
      (prev, current) =>
        current.gamesPlayed > prev.gamesPlayed ? current : prev,
      { id: 0, gamesPlayed: 0, maxRound: 0 }
    );
  }, [gameStats]);

  const bestRoundGame = useMemo(() => {
    return gameStats.reduce(
      (prev, current) => (current.maxRound > prev.maxRound ? current : prev),
      { id: 0, gamesPlayed: 0, maxRound: 0 }
    );
  }, [gameStats]);

  const updateStats = useCallback(() => {
    let totalGames = 0;
    let maxGames = 0;
    let favGame = { title: '', id: 1 };
    let bestRound = { id: 0, maxRound: 0 };

    games.forEach((game) => {
      const stats = game.getStats();
      if (stats && stats.gamesPlayed !== undefined) {
        totalGames += stats.gamesPlayed;
        if (stats.gamesPlayed > maxGames) {
          maxGames = stats.gamesPlayed;
          favGame = { title: game.info.title, id: game.info.id };
        }
        if (stats.maxRound > bestRound.maxRound) {
          bestRound = { id: game.info.id, maxRound: stats.maxRound };
        }
      }
    });

    if (maxGames === 0) {
      const defaultGame = getGame(1);
      favGame = {
        title: defaultGame?.info.title || '',
        id: defaultGame?.info.id || 1,
      };
    }

    setTotalGamesPlayed(totalGames);
    setFavoriteGame(favGame);
    setBestRoundGameId(bestRound.id);
  }, [games]);

  const [bestRoundGameId, setBestRoundGameId] = useState<number>(0); // Definir el setter correctament

  useEffect(() => {
    let currentUserId = localStorage.getItem('userId');

    if (!currentUserId) {
      currentUserId = crypto.randomUUID();
      localStorage.setItem('userId', currentUserId);
    }

    setUserId(currentUserId);

    const savedName = localStorage.getItem('userName');
    if (!savedName) {
      navigate('/?ObrirModalUsuari=true');
      return;
    }

    setUserName(savedName);

    const storedDateString = localStorage.getItem('accountCreationDate');
    if (storedDateString) {
      setAccountCreationDate(new Date(storedDateString));
    } else {
      const newCreationDate = new Date();
      localStorage.setItem(
        'accountCreationDate',
        newCreationDate.toISOString()
      );
      setAccountCreationDate(newCreationDate);
    }
    updateStats();
    setIsLoaded(true);

    const handleNameChange = (event: CustomEvent) => {
      setUserName(event.detail.name);
    };

    const handleStatsImported = () => {
      updateStats();
      // Actualitzar gameStats des de localStorage si és necessari
      const updatedStats = games.map((game) => ({
        id: game.info.id,
        gamesPlayed: game.getStats()?.gamesPlayed || 0,
        maxRound: game.getStats()?.maxRound || 0,
      }));
      setGameStats(updatedStats);
    };

    window.addEventListener('statsImported', handleStatsImported);
    window.addEventListener(
      'userNameChanged',
      handleNameChange as EventListener
    );

    return () => {
      window.removeEventListener('statsImported', handleStatsImported);
      window.removeEventListener(
        'userNameChanged',
        handleNameChange as EventListener
      );
    };
  }, [updateStats, games, navigate]);

  const resetGameStats = () => {
    games.forEach((game) => {
      const localStorageKey = game.localStorageKey;
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          gamesPlayed: 0,
          maxRound: 0,
          customStat: 0,
        })
      );
    });
    setGameStats(
      games.map((game) => ({
        id: game.info.id,
        gamesPlayed: 0,
        maxRound: 0,
      }))
    );
  };

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    resetGameStats();
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    navigate('/');
  };

  const handleChangeName = async () => {
    if (!editingName.trim()) {
      setNameError('El nom no pot estar buit');
      return;
    }
    if (editingName.length > MAX_NAME_LENGTH) {
      setNameError(`El nom no pot tenir més de ${MAX_NAME_LENGTH} caràcters.`);
      return;
    }

    try {
      localStorage.setItem('userName', editingName);
      setUserName(editingName);
      setIsEditingName(false);
      setNameError('');
      window.dispatchEvent(
        new CustomEvent('userNameChanged', {
          detail: { name: editingName },
        })
      );
    } catch (error) {
      console.error('Error en actualitzar el nom:', error);
      setNameError(
        'Error en actualitzar el nom. Si us plau, intenta-ho de nou més tard.'
      );
    }
  };

  const handleLocalNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setEditingName(value);
    }
  };

  const handleCancelNameEdit = () => {
    setIsEditingName(false);
    setEditingName(userName);
    setNameError('');
  };

  const handleEditMode = () => {
    setIsEditingName(true);
    setEditingName(userName);
  };

  const userInfoVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.5,
        ease: 'easeOut',
      },
    }),
  };

  const formatDate = (date: Date | null): string => {
    if (!date) return '-';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Ordenar jocs utilitzant la funció centralitzada
  const orderedGames = useMemo(() => {
    return sortGames(games, gameStats, mostPlayedGame.id, bestRoundGameId);
  }, [games, gameStats, mostPlayedGame.id, bestRoundGameId]);

  return (
    <div
      className={`min-h-screen flex flex-col bg-texture bg-white dark:bg-gray-900 transition-colors duration-300 ${
        isLoaded ? '' : 'opacity-0'
      }`}
    >
      <div className="flex-grow px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
        <div className="max-w-7xl mx-auto">
          <header
            className={`flex justify-between items-center mb-6 sm:mb-8 transition-all duration-300 ${
              isLoaded
                ? 'translate-y-0 opacity-100'
                : '-translate-y-10 opacity-0'
            }`}
          >
            <Link to="/" className="group">
              <div className="icon-bg">
                <ArrowLeft className="text-white w-1/2 h-1/2" />
              </div>
            </Link>
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-red-500 absolute left-1/2 transform -translate-x-1/2">
              Usuari
            </h1>
            <DarkModeToggle />
          </header>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden p-6 mb-8"
          >
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="flex flex-col md:flex-row items-center mb-6 md:mb-0 w-full md:w-auto">
                <div className="icon-bg icon-bg-large mb-4 md:mb-0 md:mr-6">
                  {userName ? (
                    <span className="text-white text-5xl md:text-6xl lg:text-7xl font-bold">
                      {userName[0].toUpperCase()}
                    </span>
                  ) : (
                    <User className="text-white w-1/2 h-1/2" />
                  )}
                </div>
                <div className="text-center md:text-left flex-1">
                  {isEditingName ? (
                    <div className="flex flex-col items-start justify-center md:justify-start mb-4">
                      <div className="flex items-center mb-1">
                        <input
                          type="text"
                          value={editingName}
                          onChange={handleLocalNameChange}
                          className="w-full p-2 border-2 border-yellow-500 rounded-md bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                          maxLength={MAX_NAME_LENGTH}
                        />
                        <button
                          onClick={handleChangeName}
                          className="text-green-500 mr-2 p-1 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                        >
                          <Check size={20} />
                        </button>
                        <button
                          onClick={handleCancelNameEdit}
                          className="text-red-500 p-1 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                        >
                          <X size={20} />
                        </button>
                      </div>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                        {editingName.length}/{MAX_NAME_LENGTH} caràcters
                      </p>
                    </div>
                  ) : (
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={userInfoVariants}
                      custom={0}
                    >
                      <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white mb-2 flex items-center justify-center md:justify-start">
                        {userName}
                        <button
                          onClick={handleEditMode}
                          className="ml-2 text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full p-1"
                        >
                          <Edit size={16} />
                        </button>
                      </h2>
                      <div className="mt-2 flex items-center space-x-2 text-gray-600 dark:text-gray-400 justify-center md:justify-start">
                        <Calendar size={16} />
                        <p className="text-sm font-normal">
                          Data de creació: {formatDate(accountCreationDate)}
                        </p>
                      </div>

                      <div className="mt-2 flex items-center space-x-2 text-gray-600 dark:text-gray-400 justify-center md:justify-start">
                        <Gamepad2 size={16} />
                        <p className="text-sm font-normal">
                          Minijoc Preferit: {favoriteGame.title || '-'}
                        </p>
                      </div>

                      <div className="mt-2 flex items-center space-x-2 text-gray-600 dark:text-gray-400 justify-center md:justify-start">
                        <List size={16} />
                        <p className="text-sm font-normal">
                          Total de Partides: {totalGamesPlayed}
                        </p>
                      </div>
                    </motion.div>
                  )}

                  {nameError && (
                    <div className="mt-2 text-center text-red-500 bg-red-100 dark:bg-red-900/30 p-2 rounded">
                      {nameError}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col space-y-2 w-full md:w-auto">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  onClick={handleLogout}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full transition transform duration-300 flex items-center justify-center shadow-md hover:shadow-lg"
                >
                  <LogOut size={20} className="mr-2" />
                  Tancar Sessió
                </motion.button>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden p-6 mb-8"
          >
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-800 dark:text-white">
                Estadístiques
              </h2>
              <button
                onClick={() => setShowStatsTransferModal(true)}
                className="flex items-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-white rounded-full px-3 sm:px-4 py-1.5 sm:py-2 transition-all duration-300 text-xs sm:text-sm transform hover:scale-105"
              >
                <Repeat size={16} className="mr-1" />
                Transferir
              </button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {' '}
              {/* AQUI LA MODIFICACIÓ */}
              {orderedGames.map((game) => {
                const tags: GameTag[] = getGameTags(
                  game.info.id,
                  gameStats,
                  mostPlayedGame.id,
                  bestRoundGameId
                );
                return (
                  <GameCard
                    key={game.info.id}
                    info={game.info}
                    stats={game.getStats()}
                    tags={tags}
                    isDarkMode={isDarkMode}
                  />
                );
              })}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden p-6"
          >
            <TopDonations limit={3} />
          </motion.div>
        </div>
      </div>
      <Footer />
      <LogoutModal
        isOpen={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        onConfirm={confirmLogout}
      />
      <PasswordChangeModal
        isOpen={showPasswordModal}
        onClose={() => setShowPasswordModal(false)}
      />
      <StatsTransferModal
        isOpen={showStatsTransferModal}
        onClose={() => setShowStatsTransferModal(false)}
        userId={userId}
      />
    </div>
  );
};

export default UserPage;
