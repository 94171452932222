import React, { useState } from 'react';
import { X, AlertTriangle } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import netlifyIdentity from 'netlify-identity-widget';
import BaseModal from './BaseModal';
import { motion, AnimatePresence } from 'framer-motion'; // Import motion

interface PasswordChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PasswordChangeModal: React.FC<PasswordChangeModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { changePassword } = useAuth();

  const handleClose = () => {
    setNewPassword('');
    setConfirmPassword('');
    setError('');
    setShowConfirmation(false);
    setIsSubmitting(false);
    onClose();
  };

  const handleForgotPassword = () => {
    netlifyIdentity.open('recovery');
    handleClose();
  };

  const handleChangePassword = async () => {
    setError('');

    if (!newPassword || !confirmPassword) {
      setError('Si us plau, omple tots els camps');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Les contrasenyes no coincideixen');
      return;
    }

    try {
      if (!showConfirmation) {
        setShowConfirmation(true);
        return;
      }
      setIsSubmitting(true);
      const user = netlifyIdentity.currentUser();
      if (!user) {
        throw new Error('No hi ha cap usuari autenticat');
      }
      await changePassword(newPassword);
      handleClose();
    } catch (error) {
      console.error('Error changing password:', error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(
          'Error en canviar la contrasenya. Si us plau, torna-ho a provar més tard.'
        );
      }
      setIsSubmitting(false);
    }
  };
  const contentVariants = {
    enter: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: 'easeOut',
      },
    },
    exit: {
      x: -50,
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: 'easeIn',
      },
    },
  };
  return (
    <BaseModal isOpen={isOpen} onClose={handleClose} maxWidth="md">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-gray-800 dark:text-white">
          Canviar Contrasenya
        </h2>
        <button
          onClick={handleClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors"
        >
          <X className="w-6 h-6" />
        </button>
      </div>

      <AnimatePresence mode="wait">
        {showConfirmation ? (
          <motion.div
            key="confirmation"
            initial={{ x: 50, opacity: 0 }}
            animate={contentVariants.enter}
            exit={contentVariants.exit}
            className="space-y-4"
          >
            <div className="flex items-center space-x-2 text-yellow-500">
              <AlertTriangle className="w-5 h-5" />
              <p className="text-sm font-medium">
                Estàs segur que vols canviar la contrasenya?
              </p>
            </div>
            <div className="flex space-x-3">
              <button
                onClick={handleClose}
                className="flex-1 bg-gray-500 hover:bg-gray-600 text-white font-bold py-2.5 px-4 rounded-lg transition-colors"
              >
                No
              </button>
              <button
                onClick={handleChangePassword}
                disabled={isSubmitting}
                className="flex-1 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2.5 px-4 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Sí, canviar
              </button>
            </div>
          </motion.div>
        ) : (
          <motion.div
            key="form"
            initial={{ x: -50, opacity: 0 }}
            animate={contentVariants.enter}
            exit={contentVariants.exit}
            className="space-y-4"
          >
            <div>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full p-2.5 border rounded-lg dark:bg-gray-700 dark:text-white dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                placeholder="Nova contrasenya"
              />
            </div>

            <div>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full p-2.5 border rounded-lg dark:bg-gray-700 dark:text-white dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                placeholder="Repeteix la nova contrasenya"
              />
            </div>

            {error && (
              <p className="text-red-500 text-sm bg-red-50 dark:bg-red-900/30 p-2.5 rounded-lg">
                {error}
              </p>
            )}

            <button
              onClick={handleForgotPassword}
              className="text-blue-500 hover:text-blue-600 text-sm underline"
            >
              Has oblidat la contrasenya?
            </button>

            <div className="flex space-x-3 pt-2">
              <button
                onClick={handleClose}
                className="flex-1 bg-gray-500 hover:bg-gray-600 text-white font-bold py-2.5 px-4 rounded-lg transition-colors"
              >
                Cancel·lar
              </button>
              <button
                onClick={handleChangePassword}
                className="flex-1 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2.5 px-4 rounded-lg transition-colors"
              >
                Continuar
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </BaseModal>
  );
};

export default PasswordChangeModal;
