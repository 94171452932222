import React from 'react';
import { X, User, Sun, Moon } from 'lucide-react';
import BaseModal from './BaseModal';
import { motion } from 'framer-motion'; // Import motion

interface InstructionsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const InstructionsModal: React.FC<InstructionsModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} maxWidth="sm">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white">
          Benvingut a minijocs.cat!
        </h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <X size={20} />
        </button>
      </div>
      <div className="space-y-3 text-sm sm:text-base text-gray-600 dark:text-gray-300">
        <p>Aquí tens algunes instruccions per començar:</p>
        <ul className="list-disc pl-5 space-y-2">
          <li>Pots jugar a qualsevol minijocs fent clic a la seva casella.</li>
          <li>
            Utilitza el botó de perfil{' '}
            <User className="inline-block w-3 h-3 sm:w-4 sm:h-4" /> a dalt a
            l'esquerra per veure el teu perfil.
          </li>
          <li>
            Utilitza el botó de mode fosc/clar{' '}
            <Sun className="inline-block w-3 h-3 sm:w-4 sm:h-4" />/
            <Moon className="inline-block w-3 h-3 sm:w-4 sm:h-4" /> a dalt a la
            dreta per canviar l'aparença de la web.
          </li>
          <li>
            Pots fer donacions si t'agraden els minijocs i vols formar part del
            top de donants.
          </li>
        </ul>
        <p>Gràcies per ser part d'aquesta aventura!</p>
      </div>
      <motion.button
        onClick={onClose}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="mt-4 sm:mt-6 w-full bg-gradient-to-r from-yellow-500 to-red-500 hover:from-yellow-600 hover:to-red-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 text-sm sm:text-base"
      >
        Començar a Jugar!
      </motion.button>
    </BaseModal>
  );
};

export default InstructionsModal;
