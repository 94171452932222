// src/components/GameCard.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { GameInfo } from '../utils/game';
import { GameTag } from '../utils/gameUtils';

interface GameCardProps {
  info: GameInfo;
  stats?: {
    gamesPlayed: number;
    maxRound: number;
    customStat: number;
    gameName: string;
  };
  showStats?: boolean;
  tags?: GameTag[];
  isDarkMode?: boolean;
}

const GameCard: React.FC<GameCardProps> = ({
  info,
  stats,
  showStats = true,
  tags = [],
  isDarkMode = false,
}) => {
  const getCustomStatLabel = (gameTitle: string) => {
    if (gameTitle === 'ClickGame') {
      return 'Clics Totals';
    }
    if (gameTitle === '2Game') {
      return 'Colors Totals';
    }
    return 'Custom Stat';
  };

  return (
    <Link
      to={info.path}
      className="block group"
      aria-label={`Jugar a ${info.title}`}
    >
      <div className="bg-white dark:bg-gray-800 rounded-lg p-2 sm:p-3 shadow-md hover:shadow-lg transition-all duration-300 transform hover:scale-102 relative overflow-hidden h-full">
        <div className="relative z-10">
          <div className="flex items-center justify-between mb-1 sm:mb-2">
            <div className="flex-1 min-w-0 pr-2">
              <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-white truncate">
                {info.title}
              </h3>
            </div>
            <div className="flex items-center">
              {tags
                .sort((a, b) => b.priority - a.priority)
                .map((tag, index) => (
                  <div
                    key={index}
                    className={`text-xs px-1 py-0.5 rounded-md mr-1 ${
                      isDarkMode ? 'text-white' : 'text-gray-800'
                    }`}
                  >
                    {tag.label}
                  </div>
                ))}
              <info.icon className="w-4 h-4 sm:w-5 sm:h-5 text-black dark:text-white flex-shrink-0 ml-1" />
            </div>
          </div>
          <div className="mt-0.5 mb-2 sm:mb-3">
            <p className="text-xs sm:text-sm text-gray-600 dark:text-gray-400 line-clamp-2 flex-grow">
              {info.description}
            </p>
          </div>
          {showStats && stats && (
            <div className="space-y-1.5">
              {stats.gamesPlayed !== undefined && (
                <div className="flex justify-between items-center text-xs sm:text-sm">
                  <span className="text-gray-600 dark:text-gray-400">
                    Partides Jugades:
                  </span>
                  <span className="font-medium text-gray-800 dark:text-white">
                    {stats.gamesPlayed}
                  </span>
                </div>
              )}
              {stats.maxRound !== undefined && (
                <div className="flex justify-between items-center text-xs sm:text-sm">
                  <span className="text-gray-600 dark:text-gray-400">
                    Millor Ronda:
                  </span>
                  <span className="font-medium text-gray-800 dark:text-white">
                    {stats.maxRound}
                  </span>
                </div>
              )}
              <div className="flex justify-between items-center text-xs sm:text-sm">
                <span className="text-gray-600 dark:text-gray-400">
                  {getCustomStatLabel(stats.gameName)}:
                </span>
                <span className="font-medium text-gray-800 dark:text-white">
                  {stats.customStat !== undefined ? stats.customStat : 0}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default GameCard;
