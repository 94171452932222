import React from 'react';
import { X } from 'lucide-react';
import BaseModal from './BaseModal';
import { motion } from 'framer-motion'; // Import motion

interface InformationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const InformationModal: React.FC<InformationModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} maxWidth="sm">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white">
          Informació important
        </h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <X size={20} />
        </button>
      </div>
      <div className="space-y-4 text-sm sm:text-base text-gray-600 dark:text-gray-300">
        <p>
          Si canvies el teu nom mentre estàs al Top, aquest no es mostrarà fins
          que realitzis una nova donació.
        </p>
        <p>
          Si fas una donació sense tenir un compte i el teu nom ja apareix al
          Top, serà substituït per "Anònim".
        </p>
        <p>
          En cas de tenir un compte i que el nom coincideixi, ens posarem en
          contacte amb tu per correu electrònic perquè el modifiquis. Fins que
          no responquis, el teu nom serà canviat a "Anònim".
        </p>
      </div>
      <motion.button
        onClick={onClose}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="mt-6 w-full bg-gradient-to-r from-yellow-500 to-red-500 hover:from-yellow-600 hover:to-red-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 text-sm sm:text-base"
      >
        Entès
      </motion.button>
    </BaseModal>
  );
};

export default InformationModal;
