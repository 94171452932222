import React, { useState, useEffect } from 'react';
import { Trophy, Gift } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import { useDonations } from '../context/DonationsContext';

interface TopDonationsProps {
  limit?: number;
}

const exampleTopDonations = [
  { name: 'Dona per aparèixer aquí', amount: 0 },
  { name: 'Dona per aparèixer aquí', amount: 0 },
  { name: 'Dona per aparèixer aquí', amount: 0 },
];

export const TopDonations: React.FC<TopDonationsProps> = ({ limit = 3 }) => {
  const {
    topDonations,
    isLoading,
    error,
    isRefreshing,
    loadFullData,
    hasFullData,
  } = useDonations();
  const [showAll, setShowAll] = useState(false);
  const location = useLocation();
  const isDonatePage = location.pathname === '/donar';
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    setShowAll(false);
  }, [location.pathname]);

  const MAX_DISPLAY_COUNT = 20;
  const displayCount = showAll ? MAX_DISPLAY_COUNT : limit;

  const displayedDonations =
    topDonations.length > 0
      ? topDonations.slice(0, displayCount)
      : exampleTopDonations.slice(0, limit);

  const hasMoreToShow = topDonations.length > displayCount;

  const handleShowMore = async () => {
    if (!hasFullData) {
      await loadFullData();
    }
    setShowAll(true);
  };

  const getTrophyColor = (index: number) => {
    switch (index) {
      case 0:
        return 'text-yellow-500';
      case 1:
        return 'text-gray-400';
      case 2:
        return 'text-orange-500';
      default:
        return 'text-gray-400';
    }
  };

  const getRowStyle = (index: number) => {
    switch (index) {
      case 0:
        return 'bg-gradient-to-r from-yellow-200 to-yellow-100 dark:from-yellow-900 dark:to-yellow-800 font-bold text-black dark:text-white';
      case 1:
        return 'bg-gradient-to-r from-gray-200 to-gray-100 dark:from-gray-600 dark:to-gray-500 font-bold text-black dark:text-white';
      case 2:
        return 'bg-gradient-to-r from-orange-200 to-orange-100 dark:from-orange-900 dark:to-orange-800 font-bold text-black dark:text-white';
      default:
        return index % 2 === 0
          ? 'bg-gray-50 dark:bg-gray-800 text-gray-800 dark:text-white'
          : 'bg-white dark:bg-gray-700 text-gray-800 dark:text-white';
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden p-8">
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-500"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="text-center p-4 text-red-500"
      >
        {error}
      </motion.div>
    );
  }

  return (
    <>
      {!isHomePage && (
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-800 dark:text-white">
            Top Donadors
          </h2>
          <Link
            to="/donar"
            className="flex items-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-white rounded-full px-3 sm:px-4 py-1.5 sm:py-2 transition-all duration-300 text-xs sm:text-sm transform hover:scale-105"
          >
            <Gift size={16} className="mr-1" />
            Fer una donació
          </Link>
        </div>
      )}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
        className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden relative"
      >
        <table className="w-full">
          <thead>
            <tr className="bg-gradient-to-r from-yellow-500 to-red-500 text-white">
              <th className="py-2 px-2 text-left w-1/4">Posició</th>
              <th className="py-2 px-2 text-center w-1/2">Nom</th>
              <th className="py-2 px-2 text-right w-1/4">Donat</th>
            </tr>
          </thead>
          <tbody>
            <AnimatePresence>
              {displayedDonations.map((donation, index) => (
                <motion.tr
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  className={`${getRowStyle(
                    index
                  )} transition-all duration-300 hover:shadow-inner`}
                >
                  <td className="py-2 px-2 flex items-center">
                    {index < 3 ? (
                      <Trophy
                        className={`${getTrophyColor(index)} mr-2`}
                        size={20}
                      />
                    ) : (
                      <span className="font-semibold">{index + 1}</span>
                    )}
                  </td>
                  <td className="py-2 px-2 font-medium text-center">
                    {donation.name}
                  </td>
                  <td className="py-2 px-2 text-right font-bold">
                    {donation.amount.toFixed(2)}€
                  </td>
                </motion.tr>
              ))}
            </AnimatePresence>
          </tbody>
        </table>

        {hasMoreToShow && isDonatePage && (
          <div className="p-4 flex justify-center">
            <button
              onClick={handleShowMore}
              className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-white rounded-full px-4 py-2 transition-all duration-300 text-sm transform hover:scale-105"
            >
              Veure més
            </button>
          </div>
        )}

        {isRefreshing && (
          <div className="absolute inset-0 bg-black bg-opacity-10 dark:bg-opacity-20 flex items-center justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-500"></div>
          </div>
        )}
      </motion.div>
    </>
  );
};
