import React, { useState, useEffect, useRef } from 'react';
import { X, Clipboard, Copy } from 'lucide-react';
import { encryptStats, importStats } from '../utils/statsEncryption';
import BaseModal from './BaseModal';
import { motion, AnimatePresence } from 'framer-motion';

interface StatsTransferModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
}

interface Message {
  id: string;
  text: string;
  type: 'error' | 'info';
}

const StatsTransferModal: React.FC<StatsTransferModalProps> = ({
  isOpen,
  onClose,
  userId,
}) => {
  const [mode, setMode] = useState<'export' | 'import'>('export');
  const [code, setCode] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const exportTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const importTextAreaRef = useRef<HTMLTextAreaElement>(null);

  const addMessage = (text: string, type: 'error' | 'info') => {
    const id = crypto.randomUUID();
    setMessages([{ id, text, type }]);
  };

  useEffect(() => {
    if (isOpen) {
      setMode('export');
      setCode(encryptStats(userId));
      setMessages([]);
    }
  }, [isOpen, userId]);

  const handleClose = () => {
    setCode('');
    setMessages([]);
    onClose();
  };

  const handleCopyCode = () => {
    if (exportTextAreaRef.current) {
      exportTextAreaRef.current.select();
      document.execCommand('copy');
      addMessage('Codi copiat al portapapers!', 'info');
    }
  };

  const handlePasteCode = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setCode(clipboardText);
      addMessage('Codi enganxat des del portapapers.', 'info');
    } catch (err) {
      addMessage(
        'Error al enganxar el text. Assegura’t de permetre l’accés al portapapers.',
        'error'
      );
    }
  };
  const handleImport = () => {
    try {
      const success = importStats(code, userId);
      if (success) {
        addMessage('Estadístiques importades correctament!', 'info');
        setTimeout(() => {
          window.dispatchEvent(new CustomEvent('statsImported'));
        }, 100);
        handleClose();
      } else {
        addMessage("El codi d'estadístiques no és vàlid.", 'error');
      }
    } catch (err) {
      addMessage(
        'Error al importar les estadístiques. Comprova el codi i torna-ho a intentar.',
        'error'
      );
    }
  };

  const contentVariants = {
    initial: (direction: number) => ({
      x: direction > 0 ? 50 : -50,
      opacity: 0,
    }),
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: 'easeOut',
      },
    },
    exit: (direction: number) => ({
      x: direction > 0 ? -50 : 50,
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: 'easeIn',
      },
    }),
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={handleClose}
      maxWidth="md"
      className="relative"
    >
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-gray-800 dark:text-white">
          Transferir Estadístiques
        </h2>
        <button
          onClick={handleClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors"
          aria-label="Tancar modal"
        >
          <X className="w-6 h-6" />
        </button>
      </div>
      <div className="flex rounded-lg bg-gray-100 dark:bg-gray-700 p-1 mb-6">
        <button
          onClick={() => {
            setMode('export');
            setCode(encryptStats(userId));
            setMessages([]);
          }}
          className={`flex-1 py-2 px-4 rounded-md transition-colors ${
            mode === 'export'
              ? 'bg-gradient-to-r from-yellow-500 to-red-500 text-white'
              : 'text-gray-700 dark:text-gray-300'
          }`}
        >
          Exportar
        </button>
        <button
          onClick={() => {
            setMode('import');
            setCode('');
            setMessages([]);
          }}
          className={`flex-1 py-2 px-4 rounded-md transition-colors ${
            mode === 'import'
              ? 'bg-gradient-to-r from-yellow-500 to-red-500 text-white'
              : 'text-gray-700 dark:text-gray-300'
          }`}
        >
          Importar
        </button>
      </div>

      <AnimatePresence mode="wait" initial={false}>
        {mode === 'export' ? (
          <motion.div
            key="export"
            custom={1}
            variants={contentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            layout
            className="space-y-4"
          >
            <div className="relative">
              <div className="flex items-center justify-between mb-2">
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  El teu codi d'estadístiques:
                </p>
                <button
                  onClick={handleCopyCode}
                  className="p-1.5 rounded-lg bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500 transition-colors"
                  aria-label="Copiar codi d'estadístiques"
                >
                  <Copy size={16} />
                </button>
              </div>
              <textarea
                ref={exportTextAreaRef}
                value={code}
                readOnly
                className="w-full h-28 p-3 border rounded-lg bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-white text-sm font-mono resize-none"
              />
            </div>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Copia aquest codi i enganxa’l al dispositiu on vulguis importar
              les estadístiques. Aquest codi està lligat al teu identificador
              d'usuari per garantir que només tu puguis importar les teves
              dades.
            </p>
            {messages.map((message) => (
              <motion.p
                key={message.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                className={`text-sm p-2.5 rounded-lg ${
                  message.type === 'error'
                    ? 'text-red-500 bg-red-50 dark:bg-red-900/30'
                    : 'text-green-500 bg-green-100 dark:bg-green-900/30'
                }`}
              >
                {message.text}
              </motion.p>
            ))}
          </motion.div>
        ) : (
          <motion.div
            key="import"
            custom={-1}
            variants={contentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            layout
            className="space-y-4"
          >
            <div>
              <div className="flex items-center justify-between mb-2">
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Enganxa el codi d'estadístiques:
                </p>
                <button
                  onClick={handlePasteCode}
                  className="p-1.5 rounded-lg bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500 transition-colors"
                  aria-label="Enganxar codi d'estadístiques"
                >
                  <Clipboard size={16} />
                </button>
              </div>
              <textarea
                ref={importTextAreaRef}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="w-full h-28 p-3 border rounded-lg bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-white text-sm font-mono resize-none"
                placeholder="Enganxa aquí el codi"
              />
            </div>
            {messages.map((message) => (
              <motion.p
                key={message.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                className={`text-sm p-2.5 rounded-lg ${
                  message.type === 'error'
                    ? 'text-red-500 bg-red-50 dark:bg-red-900/30'
                    : 'text-green-500 bg-green-100 dark:bg-green-900/30'
                }`}
              >
                {message.text}
              </motion.p>
            ))}
            <motion.button
              onClick={handleImport}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full bg-gradient-to-r from-yellow-500 to-red-500 hover:from-yellow-600 hover:to-red-600 text-white font-bold py-2.5 px-4 rounded-lg transition-colors"
            >
              Importar Estadístiques
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>
    </BaseModal>
  );
};

export default StatsTransferModal;
