import React from 'react';
import { Link } from 'react-router-dom';
import { motion, useReducedMotion } from 'framer-motion';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const shouldReduceMotion = useReducedMotion();

  return (
    <motion.footer
      initial={!shouldReduceMotion ? { y: 50, opacity: 0 } : { opacity: 1 }}
      animate={!shouldReduceMotion ? { y: 0, opacity: 1 } : { opacity: 1 }}
      transition={!shouldReduceMotion ? { type: 'spring', stiffness: 50, duration: 0.5 } : {}}
      className="w-full mt-2 mb-4 flex justify-center"
    >
      <div className="text-[10px] xs:text-xs sm:text-sm text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-800 px-2 xs:px-3 sm:px-4 py-1 xs:py-2 sm:py-3 rounded-full shadow-md font-normal">
        <div className="flex flex-col sm:flex-row sm:items-center justify-center sm:gap-x-1">
          <span className="text-center whitespace-nowrap mb-1 sm:mb-0">
            © {currentYear} minijocs.cat
          </span>
          <div className="hidden sm:block">|</div>
          <Link
            to="/legal"
            className="hover:opacity-80 transition-opacity whitespace-nowrap text-center mb-1 sm:mb-0"
          >
            Informació Legal i Polítiques
          </Link>
          <div className="hidden sm:block">|</div>
          <a
            href="https://x.com/minijocscat"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80 transition-opacity inline-flex items-center justify-center whitespace-nowrap"
            aria-label="Segueix-nos a X (Twitter)"
          >
            Segueix-nos a
            <svg
              viewBox="0 0 24 24"
              className="w-2.5 h-2.5 xs:w-2.5 xs:h-2.5 sm:w-3.5 sm:h-3.5 fill-current ml-1"
              aria-hidden="true"
            >
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
            </svg>
          </a>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;