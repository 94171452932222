// gameInfo
import { GameInfo } from '../../utils/game';
import { MousePointer } from 'lucide-react';

export const GAME_INFO: GameInfo = {
  id: 1,
  title: 'Clica',
  description: 'Fes clic abans que desapareguin!',
  icon: MousePointer,
  path: '/clica',
  theme: {
    gradient: {
      from: 'yellow-500',
      to: 'red-500',
    },
    pattern: {
      type: 'dots',
      color: 'currentColor',
      opacity: 0,
      size: 4,
      spacing: 16,
    },
  },
};
