import React, { ReactNode } from 'react';

interface TooltipProps {
  content: string;
  children: ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => (
  <div className="relative flex items-center justify-center group">
    {children}
    <div className="absolute bottom-full mb-2 hidden px-2 py-1 text-xs text-white bg-black rounded-md group-hover:block whitespace-nowrap z-10">
      {content}
    </div>
  </div>
);

export default Tooltip;
