import React, {
  useState,
  useEffect,
  Suspense,
  useRef,
  useCallback,
} from 'react';
import { useParams, Link, Navigate, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import DarkModeToggle from './components/DarkModeToggle';
import Footer from './components/Footer';
import { getGameByPath, getNextGame, getPreviousGame } from './games/registry';

const GamePage: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isGameReady, setIsGameReady] = useState(false);
  const [isGameShown, setIsGameShown] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [hoverSide, setHoverSide] = useState<'left' | 'right' | 'none'>('none');
  const [touchStart, setTouchStart] = useState<number | null>(null);

  const { path } = useParams<{ path: string }>();
  const navigate = useNavigate();

  const game = path ? getGameByPath(`/${path}`) : null;
  const GameComponent = game?.component;

  const nextGame = game ? getNextGame(game.info.id) : null;
  const previousGame = game ? getPreviousGame(game.info.id) : null;
  const isFirstLoad = useRef(true);

  // Manejar el resize de la ventana
  const handleResize = useCallback(() => {
    setWindowHeight(window.innerHeight);
    setIsMobile(window.innerWidth <= 768);
  }, []);

  // Manejar el movimiento del mouse para los hover effects
  const handleGlobalMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isMobile) return;

      const threshold = 0.3;
      const leftThreshold = window.innerWidth * threshold;
      const rightThreshold = window.innerWidth * (1 - threshold);

      if (e.clientX < leftThreshold) {
        setHoverSide('left');
      } else if (e.clientX > rightThreshold) {
        setHoverSide('right');
      } else {
        setHoverSide('none');
      }
    },
    [isMobile]
  );

  const handleComponentLoad = useCallback(() => {
    setIsLoaded(true);

    if (isFirstLoad.current) {
      setTimeout(() => {
        setIsGameReady(true);
        setTimeout(() => {
          setIsGameShown(true);
        }, 300);
      }, 700);
    } else {
      setIsGameReady(true);
      setIsGameShown(true);
    }
    isFirstLoad.current = false;
  }, [isFirstLoad, setIsLoaded, setIsGameReady, setIsGameShown]);

  useEffect(() => {
    setIsLoaded(false);
    setIsGameReady(false);
    setIsGameShown(false);

    if (GameComponent) {
      handleComponentLoad();
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('mousemove', handleGlobalMouseMove);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleGlobalMouseMove);
    };
  }, [
    path,
    isMobile,
    GameComponent,
    handleComponentLoad,
    handleGlobalMouseMove,
    handleResize,
  ]);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (!touchStart) return;

    const touchEnd = e.changedTouches[0].clientX;
    const diff = touchStart - touchEnd;

    if (Math.abs(diff) > 50) {
      if (diff > 0 && nextGame) {
        handleNavigation(nextGame.info.path);
      } else if (diff < 0 && previousGame) {
        handleNavigation(previousGame.info.path);
      }
    }

    setTouchStart(null);
  };

  const handleNavigation = useCallback(
    (path: string) => {
      setIsLoaded(false);
      setIsGameShown(false);
      setIsGameReady(false);
      setTimeout(() => {
        navigate(path);
      }, 300);
    },
    [navigate, setIsLoaded, setIsGameReady, setIsGameShown]
  );

  if (!game) {
    return <Navigate to="/" replace />;
  }

  const contentHeight = Math.max(400, windowHeight);

  return (
    <div className="h-screen flex flex-col bg-texture bg-white dark:bg-gray-900">
      <div
        className={`flex-grow px-2 sm:px-6 lg:px-8 py-2 sm:py-8 ${
          isMobile ? 'overflow-auto' : 'overflow-hidden'
        }`}
      >
        <div className="max-w-7xl mx-auto h-full flex flex-col">
          <header
            className={`flex justify-between items-center mb-2 sm:mb-8 transition-all duration-300 ${
              isLoaded
                ? 'translate-y-0 opacity-100'
                : '-translate-y-10 opacity-0'
            }`}
          >
            <Link to="/" className="group">
              <div className="icon-bg">
                <ArrowLeft className="text-white w-1/2 h-1/2" />
              </div>
            </Link>
            <h1 className="text-xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-red-500 absolute left-1/2 transform -translate-x-1/2">
              {game.info.title}
            </h1>
            <DarkModeToggle />
          </header>

          <div
            className={`relative bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden transition-all duration-300 ${
              isLoaded
                ? 'translate-y-0 opacity-100'
                : 'translate-y-10 opacity-0'
            }`}
            style={{
              height: `${contentHeight}px`,
              minHeight: '400px',
            }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            {!isMobile && previousGame && (
              <button
                onClick={() => handleNavigation(previousGame.info.path)}
                className={`absolute left-[-70px] top-1/2 transform -translate-y-1/2 z-10 transition-transform duration-300 ${
                  hoverSide === 'left' ? 'translate-x-[85px]' : ''
                }`}
                style={{
                  width: '50px',
                  height: '50px',
                }}
              >
                <div className="icon-bg">
                  <ArrowLeft className="text-white w-1/2 h-1/2" />
                </div>
              </button>
            )}

            {!isMobile && nextGame && (
              <button
                onClick={() => handleNavigation(nextGame.info.path)}
                className={`absolute right-[-70px] top-1/2 transform -translate-y-1/2 z-10 transition-transform duration-300 ${
                  hoverSide === 'right' ? '-translate-x-[85px]' : ''
                }`}
                style={{
                  width: '50px',
                  height: '50px',
                }}
              >
                <div className="icon-bg">
                  <ArrowLeft className="text-white w-1/2 h-1/2 rotate-180" />
                </div>
              </button>
            )}

            {!isGameReady ? (
              <div className="flex items-center justify-center h-full">
                <div className="animate-spin rounded-full h-32 w-32 border-b-8 border-yellow-500"></div>
              </div>
            ) : (
              <div
                className={`w-full h-full flex items-center justify-center transition-all duration-700 ease-in-out ${
                  isGameShown ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
                }`}
              >
                {isGameShown && GameComponent ? (
                  <Suspense
                    fallback={
                      <div className="animate-spin rounded-full h-32 w-32 border-b-8 border-yellow-500"></div>
                    }
                  >
                    <GameComponent />
                  </Suspense>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`transition-all duration-300 ${
          isLoaded ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
        }`}
      >
        {!isMobile && <Footer />}
      </div>
    </div>
  );
};

export default GamePage;
