import React from 'react';

const Logo: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 375 375"
    className={`${className}`}
    preserveAspectRatio="xMidYMid meet"
  >
    <defs>
      <clipPath id="clip-path-1">
        <path d="M 375 189.898438 L 375 285.523438 L 285.523438 285.523438 L 285.523438 375 L 189.898438 375 L 100.425781 285.523438 L 0 185.101562 L 127.5 86.550781 L 185.101562 0 L 285.523438 100.425781 Z M 375 189.898438 " />
      </clipPath>
      <clipPath id="clip-path-2">
        <path d="M 89.476562 0 L 185.101562 0 L 185.101562 89.476562 L 89.476562 89.476562 Z M 0 89.476562 L 274.574219 89.476562 L 274.574219 185.101562 L 0 185.101562 Z M 89.476562 185.101562 L 185.101562 185.101562 L 185.101562 274.574219 L 89.476562 274.574219 Z M 89.476562 185.101562 " />
      </clipPath>
      <linearGradient id="logo-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" className="text-yellow-500 dark:text-yellow-400" stopColor="currentColor" />
        <stop offset="100%" className="text-red-500 dark:text-red-400" stopColor="currentColor" />
      </linearGradient>
    </defs>
    <g clipPath="url(#clip-path-1)">
      <rect x="-37.5" width="450" y="-37.499999" height="449.999989" fill="url(#logo-gradient)" />
    </g>
    <g clipPath="url(#clip-path-2)" className="transition-colors duration-300 fill-white dark:fill-gray-800">
      <path d="M 0 0 L 0 274.574219 L 274.574219 274.574219 L 274.574219 0 Z M 0 0 " />
    </g>
  </svg>
);

export default Logo;