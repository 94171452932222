// src/components/DynamicTitle.tsx
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getGameByPath } from '../games/registry';

const DynamicTitle = () => {
  const location = useLocation();

  useEffect(() => {
    let title = 'minijocs.cat';

    if (location.pathname === '/') {
      title = 'minijocs.cat';
    } else if (location.pathname === '/donar') {
      title = 'minijocs.cat/donar';
    } else if (location.pathname === '/usuari') {
      title = 'minijocs.cat/usuari';
    } else if (location.pathname === '/legal') {
      title = 'minijocs.cat/legal';
    } else {
      const game = getGameByPath(location.pathname);
      if (game && game.info.title) {
        title = `minijocs.cat/${game.info.title.toLowerCase()}`;
      } else {
        title = 'minijocs.cat/error';
      }
    }

    document.title = title;
  }, [location]);

  return null;
};

export default DynamicTitle;
