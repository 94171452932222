import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  X,
  Edit,
  Save,
  Trash2,
  Users,
  CreditCard,
  Plus,
  Search,
  MessageSquare,
  Check,
  ChevronUp,
  ChevronDown,
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { createPortal } from 'react-dom';
import Tooltip from './Tooltip';

interface User {
  id: string;
  name: string;
  email?: string;
  totalDonated: number;
  lastDonationDate: string;
  role?: string;
}

interface Donation {
  id: string;
  name: string;
  amount: number;
  date: string;
  message?: string;
  userId?: string;
}

interface Message {
  id: string;
  type: string;
  message: string;
  metadata: {
    userId?: string;
    userName?: string;
    originalName?: string;
    email?: string;
  };
  date: string;
  resolved: boolean;
  resolvedAt?: string;
  resolvedBy?: string;
}

interface AdminModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AdminModal: React.FC<AdminModalProps> = ({ isOpen, onClose }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [donations, setDonations] = useState<Donation[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [editingUser, setEditingUser] = useState<string | null>(null);
  const [editingDonation, setEditingDonation] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<
    'users' | 'donations' | 'messages'
  >('users');
  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    role: 'user',
    totalDonated: 0,
  });
  const [newDonation, setNewDonation] = useState({
    name: '',
    amount: 0,
    message: '',
    date: new Date().toISOString(),
  });
  const { getToken } = useAuth();

  // Estado para el ordenamiento
  const [sortConfig, setSortConfig] = useState<{
    [key: string]: { field: string; order: 'asc' | 'desc' };
  }>({
    users: { field: '', order: 'asc' },
    donations: { field: '', order: 'asc' },
    messages: { field: '', order: 'asc' },
  });

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const token = await getToken();

      if (!token) {
        throw new Error('No hay token de autenticación disponible');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      const [usersResponse, donationsResponse, messagesResponse] =
        await Promise.all([
          fetch('/.netlify/functions/admin-get-users', { headers }),
          fetch('/.netlify/functions/admin-get-donations', { headers }),
          fetch('/.netlify/functions/admin-get-messages', { headers }),
        ]);

      if (!usersResponse.ok || !donationsResponse.ok || !messagesResponse.ok) {
        throw new Error('Error al obtener los datos');
      }

      const usersData = await usersResponse.json();
      const donationsData = await donationsResponse.json();
      const messagesData = await messagesResponse.json();

      setUsers(usersData);
      setDonations(donationsData);
      setMessages(messagesData);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      setError('Error cargando los datos. Por favor, inténtalo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };

  // Función para manejar el ordenamiento
  const handleSort = (field: string) => {
    setSortConfig((prevConfig) => {
      const currentOrder = prevConfig[activeTab].order;
      const newOrder =
        prevConfig[activeTab].field === field && currentOrder === 'asc'
          ? 'desc'
          : 'asc';
      return {
        ...prevConfig,
        [activeTab]: { field, order: newOrder },
      };
    });
  };

  // Función para mostrar indicador de ordenamiento
  const getSortIndicator = (field: string) => {
    const { field: sortField, order } = sortConfig[activeTab];
    if (sortField === field) {
      return order === 'asc' ? (
        <ChevronUp className="inline-block w-4 h-4" />
      ) : (
        <ChevronDown className="inline-block w-4 h-4" />
      );
    } else {
      return null;
    }
  };

  // Filtrar y ordenar mensajes
  const filteredMessages = messages.filter((message) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      message.message.toLowerCase().includes(searchLower) ||
      (message.metadata.userName || '').toLowerCase().includes(searchLower) ||
      (message.metadata.email || '').toLowerCase().includes(searchLower) ||
      message.type.toLowerCase().includes(searchLower)
    );
  });

  const sortedMessages = [...filteredMessages].sort((a, b) => {
    const { field, order } = sortConfig['messages'];
    if (!field) return 0; // No hay ordenamiento

    let aValue = a[field as keyof Message];
    let bValue = b[field as keyof Message];

    // Manejar campos undefined o null
    if (aValue === undefined || aValue === null) aValue = '';
    if (bValue === undefined || bValue === null) bValue = '';

    if (field === 'date') {
      const aDate = new Date(aValue as string).getTime();
      const bDate = new Date(bValue as string).getTime();
      return order === 'asc' ? aDate - bDate : bDate - aDate;
    } else if (field === 'resolved') {
      // Comparación booleana
      return order === 'asc'
        ? aValue === bValue
          ? 0
          : aValue
          ? -1
          : 1
        : aValue === bValue
        ? 0
        : aValue
        ? 1
        : -1;
    } else {
      // Comparación de cadenas
      return order === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    }
  });

  // Filtrar y ordenar usuarios
  const filteredUsers = users.filter((user) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (user.name || '').toLowerCase().includes(searchLower) ||
      (user.email || '').toLowerCase().includes(searchLower) ||
      (user.role || '').toLowerCase().includes(searchLower) ||
      user.totalDonated.toString().includes(searchLower)
    );
  });

  const sortedUsers = [...filteredUsers].sort((a, b) => {
    const { field, order } = sortConfig['users'];
    if (!field) return 0; // No hay ordenamiento

    let aValue = a[field as keyof User];
    let bValue = b[field as keyof User];

    // Manejar campos undefined o null
    if (aValue === undefined || aValue === null) aValue = '';
    if (bValue === undefined || bValue === null) bValue = '';

    if (field === 'totalDonated') {
      // Campo numérico
      return order === 'asc'
        ? (aValue as number) - (bValue as number)
        : (bValue as number) - (aValue as number);
    } else {
      // Comparación de cadenas
      return order === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    }
  });

  // Filtrar y ordenar donaciones
  const filteredDonations = donations.filter((donation) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      donation.name.toLowerCase().includes(searchLower) ||
      donation.amount.toString().includes(searchLower) ||
      (donation.message || '').toLowerCase().includes(searchLower)
    );
  });

  const sortedDonations = [...filteredDonations].sort((a, b) => {
    const { field, order } = sortConfig['donations'];
    if (!field) return 0; // No hay ordenamiento

    let aValue = a[field as keyof Donation];
    let bValue = b[field as keyof Donation];

    // Manejar campos undefined o null
    if (aValue === undefined || aValue === null) aValue = '';
    if (bValue === undefined || bValue === null) bValue = '';

    if (field === 'amount') {
      // Campo numérico
      return order === 'asc'
        ? (aValue as number) - (bValue as number)
        : (bValue as number) - (aValue as number);
    } else if (field === 'date') {
      const aDate = new Date(aValue as string).getTime();
      const bDate = new Date(bValue as string).getTime();
      return order === 'asc' ? aDate - bDate : bDate - aDate;
    } else {
      // Comparación de cadenas
      return order === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    }
  });

  // Funciones para manejar edición, creación y eliminación (sin cambios)
  const handleUserEdit = async (
    userId: string,
    updatedFields: Partial<User>
  ) => {
    try {
      const token = await getToken();
      if (!token) throw new Error('No hay token de autenticación disponible');

      const response = await fetch('/.netlify/functions/admin-update-user', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, ...updatedFields }),
      });

      if (!response.ok) {
        const errorData = await response.text();
        throw new Error(
          `Error al editar el usuario: ${response.status} ${errorData}`
        );
      }

      const updatedUser = await response.json();
      setUsers(
        users.map((user) =>
          user.id === userId ? { ...user, ...updatedFields } : user
        )
      );
      setEditingUser(null);
      setSuccess('Usuario actualizado correctamente.');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error al editar el usuario:', error);
      setError('Error al editar el usuario.');
      setTimeout(() => setError(null), 5000);
    }
  };

  const handleDonationEdit = async (
    donationId: string,
    updatedFields: Partial<Donation>
  ) => {
    try {
      const token = await getToken();
      if (!token) throw new Error('No hay token de autenticación disponible');

      const response = await fetch('/.netlify/functions/admin-edit-donation', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: donationId, ...updatedFields }),
      });

      if (!response.ok) {
        throw new Error('Error al editar la donación');
      }

      const updatedDonation = await response.json();
      setDonations(
        donations.map((donation) =>
          donation.id === donationId
            ? { ...donation, ...updatedDonation }
            : donation
        )
      );
      setEditingDonation(null);
      setSuccess('Donación actualizada correctamente.');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error al editar la donación:', error);
      setError('Error al editar la donación.');
      setTimeout(() => setError(null), 5000);
    }
  };

  const handleCreateUser = async () => {
    try {
      const token = await getToken();
      if (!token) throw new Error('No hay token de autenticación disponible');

      const response = await fetch('/.netlify/functions/admin-create-user', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newUser),
      });

      if (!response.ok) {
        throw new Error('Error al crear el usuario');
      }

      const createdUser = await response.json();
      setUsers([...users, createdUser]);
      setIsCreating(false);
      setNewUser({ name: '', email: '', role: 'user', totalDonated: 0 });
      setSuccess('Usuario creado correctamente.');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error al crear el usuario:', error);
      setError('Error al crear el usuario.');
      setTimeout(() => setError(null), 5000);
    }
  };

  const handleCreateDonation = async () => {
    try {
      const token = await getToken();
      if (!token) throw new Error('No hay token de autenticación disponible');

      const response = await fetch(
        '/.netlify/functions/admin-create-donation',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newDonation),
        }
      );

      if (!response.ok) {
        throw new Error('Error al crear la donación');
      }

      const createdDonation = await response.json();
      setDonations([...donations, createdDonation]);
      setIsCreating(false);
      setNewDonation({
        name: '',
        amount: 0,
        message: '',
        date: new Date().toISOString(),
      });
      setSuccess('Donación creada correctamente.');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error al crear la donación:', error);
      setError('Error al crear la donación.');
      setTimeout(() => setError(null), 5000);
    }
  };

  const handleUserDelete = async (userId: string) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar este usuario?')) {
      return;
    }

    try {
      const token = await getToken();
      if (!token) throw new Error('No hay token de autenticación disponible');

      const response = await fetch('/.netlify/functions/admin-delete-user', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: userId }),
      });

      if (!response.ok) {
        throw new Error('Error al eliminar el usuario');
      }

      setUsers(users.filter((user) => user.id !== userId));
      setSuccess('Usuario eliminado correctamente.');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
      setError('Error al eliminar el usuario.');
      setTimeout(() => setError(null), 5000);
    }
  };

  const handleDonationDelete = async (donationId: string) => {
    if (
      !window.confirm('¿Estás seguro de que deseas eliminar esta donación?')
    ) {
      return;
    }

    try {
      const token = await getToken();
      if (!token) throw new Error('No hay token de autenticación disponible');

      const response = await fetch(
        '/.netlify/functions/admin-delete-donation',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: donationId }),
        }
      );

      if (!response.ok) {
        throw new Error('Error al eliminar la donación');
      }

      setDonations(donations.filter((donation) => donation.id !== donationId));
      setSuccess('Donación eliminada correctamente.');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error al eliminar la donación:', error);
      setError('Error al eliminar la donación.');
      setTimeout(() => setError(null), 5000);
    }
  };

  // Nueva función para manejar la eliminación de mensajes
  const handleMessageDelete = async (messageId: string) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar este mensaje?')) {
      return;
    }

    try {
      const token = await getToken();
      if (!token) throw new Error('No hay token de autenticación disponible');

      const response = await fetch('/.netlify/functions/admin-delete-message', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: messageId }),
      });

      if (!response.ok) {
        throw new Error('Error al eliminar el mensaje');
      }

      setMessages(messages.filter((message) => message.id !== messageId));
      setSuccess('Mensaje eliminado correctamente.');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error al eliminar el mensaje:', error);
      setError('Error al eliminar el mensaje.');
      setTimeout(() => setError(null), 5000);
    }
  };

  const handleResolveMessage = async (messageId: string, resolved: boolean) => {
    try {
      const token = await getToken();
      if (!token) throw new Error('No hay token de autenticación disponible');

      const response = await fetch(
        '/.netlify/functions/admin-resolve-message',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ messageId, resolved }),
        }
      );

      if (!response.ok) {
        throw new Error('Error al resolver el mensaje');
      }

      setMessages(
        messages.map((message) =>
          message.id === messageId ? { ...message, resolved } : message
        )
      );
      setSuccess(
        `Mensaje marcado como ${resolved ? 'resuelto' : 'pendiente'}.`
      );
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error al resolver el mensaje:', error);
      setError('Error al resolver el mensaje.');
      setTimeout(() => setError(null), 5000);
    }
  };

  // Renderizado del modal
  const modalContent = (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center">
            {/* Backdrop */}
            <motion.div
              key="backdrop"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="fixed inset-0 bg-black"
              aria-hidden="true"
              onClick={onClose}
            />
            {/* Modal */}
            <motion.div
              key="modal"
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.2 }}
              className="inline-block w-full max-w-7xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-lg relative"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Botón de Cerrar */}
              <div className="absolute top-4 right-4">
                <Tooltip content="Cerrar">
                  <button
                    onClick={onClose}
                    className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 focus:outline-none"
                  >
                    <X size={24} />
                  </button>
                </Tooltip>
              </div>

              {/* Título */}
              <div className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">
                  Panel de Administración
                </h2>
              </div>

              {/* Navegación por Pestañas */}
              <div className="flex justify-between items-center border-b border-gray-200 dark:border-gray-700 mb-6">
                <div className="flex space-x-2">
                  <button
                    className={`flex items-center px-4 py-2 rounded-t-lg focus:outline-none ${
                      activeTab === 'users'
                        ? 'bg-yellow-500 text-white'
                        : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                    } transition-colors duration-200`}
                    onClick={() => {
                      setActiveTab('users');
                      setIsCreating(false);
                      setSearchTerm('');
                    }}
                  >
                    <Users className="w-5 h-5 mr-2" />
                    Usuarios{' '}
                    <span className="ml-1 text-sm bg-gray-200 dark:bg-gray-700 px-2 py-0.5 rounded-full">
                      {users.length}
                    </span>
                  </button>
                  <button
                    className={`flex items-center px-4 py-2 rounded-t-lg focus:outline-none ${
                      activeTab === 'donations'
                        ? 'bg-yellow-500 text-white'
                        : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                    } transition-colors duration-200`}
                    onClick={() => {
                      setActiveTab('donations');
                      setIsCreating(false);
                      setSearchTerm('');
                    }}
                  >
                    <CreditCard className="w-5 h-5 mr-2" />
                    Donaciones{' '}
                    <span className="ml-1 text-sm bg-gray-200 dark:bg-gray-700 px-2 py-0.5 rounded-full">
                      {donations.length}/20
                    </span>
                  </button>
                  <button
                    className={`flex items-center px-4 py-2 rounded-t-lg focus:outline-none ${
                      activeTab === 'messages'
                        ? 'bg-yellow-500 text-white'
                        : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                    } transition-colors duration-200`}
                    onClick={() => {
                      setActiveTab('messages');
                      setIsCreating(false);
                      setSearchTerm('');
                    }}
                  >
                    <MessageSquare className="w-5 h-5 mr-2" />
                    Mensajes{' '}
                    <span className="ml-1 text-sm bg-gray-200 dark:bg-gray-700 px-2 py-0.5 rounded-full">
                      {messages.length}
                    </span>
                  </button>
                </div>
                <div className="flex items-center space-x-3">
                  {/* Barra de Búsqueda */}
                  <div className="relative">
                    <input
                      type="text"
                      placeholder={`Buscar ${activeTab}...`}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-60 px-4 py-2 pr-10 text-sm border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    />
                    <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
                  </div>
                  {/* Botón Crear */}
                  {activeTab !== 'messages' && (
                    <button
                      onClick={() => setIsCreating(true)}
                      className="flex items-center px-3 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors duration-200"
                    >
                      <Plus className="w-5 h-5 mr-1" />
                      Crear {activeTab === 'users' ? 'Usuario' : 'Donación'}
                    </button>
                  )}
                </div>
              </div>

              {/* Mensajes de Error y Éxito */}
              <div className="mb-4">
                {error && (
                  <div className="p-4 mb-2 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800">
                    {error}
                  </div>
                )}
                {success && (
                  <div className="p-4 mb-2 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800">
                    {success}
                  </div>
                )}
              </div>

              {/* Contenido Principal */}
              {isLoading ? (
                <div className="flex justify-center items-center py-8">
                  <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-yellow-500"></div>
                </div>
              ) : isCreating ? (
                <div className="bg-gray-50 dark:bg-gray-700 p-6 rounded-lg">
                  {activeTab === 'users' ? (
                    <div className="space-y-4">
                      <input
                        type="text"
                        placeholder="Nombre"
                        value={newUser.name}
                        onChange={(e) =>
                          setNewUser({ ...newUser, name: e.target.value })
                        }
                        className="w-full p-2 border rounded-lg dark:bg-gray-600 dark:border-gray-500 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                      />
                      <input
                        type="email"
                        placeholder="Correo Electrónico"
                        value={newUser.email}
                        onChange={(e) =>
                          setNewUser({ ...newUser, email: e.target.value })
                        }
                        className="w-full p-2 border rounded-lg dark:bg-gray-600 dark:border-gray-500 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                      />
                      <select
                        value={newUser.role}
                        onChange={(e) =>
                          setNewUser({ ...newUser, role: e.target.value })
                        }
                        className="w-full p-2 border rounded-lg dark:bg-gray-600 dark:border-gray-500 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                      >
                        <option value="user">Usuario</option>
                        <option value="admin">Administrador</option>
                      </select>
                      <input
                        type="number"
                        placeholder="Total Donado (€)"
                        value={newUser.totalDonated}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            totalDonated: parseFloat(e.target.value),
                          })
                        }
                        className="w-full p-2 border rounded-lg dark:bg-gray-600 dark:border-gray-500 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                      />
                      <div className="flex justify-end space-x-3">
                        <button
                          onClick={() => setIsCreating(false)}
                          className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
                        >
                          Cancelar
                        </button>
                        <button
                          onClick={handleCreateUser}
                          className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors duration-200"
                        >
                          Crear Usuario
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-4">
                      <input
                        type="text"
                        placeholder="Nombre"
                        value={newDonation.name}
                        onChange={(e) =>
                          setNewDonation({
                            ...newDonation,
                            name: e.target.value,
                          })
                        }
                        className="w-full p-2 border rounded-lg dark:bg-gray-600 dark:border-gray-500 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                      />
                      <input
                        type="number"
                        placeholder="Cantidad (€)"
                        value={newDonation.amount}
                        onChange={(e) =>
                          setNewDonation({
                            ...newDonation,
                            amount: parseFloat(e.target.value),
                          })
                        }
                        className="w-full p-2 border rounded-lg dark:bg-gray-600 dark:border-gray-500 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                      />
                      <input
                        type="text"
                        placeholder="Mensaje"
                        value={newDonation.message}
                        onChange={(e) =>
                          setNewDonation({
                            ...newDonation,
                            message: e.target.value,
                          })
                        }
                        className="w-full p-2 border rounded-lg dark:bg-gray-600 dark:border-gray-500 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                      />
                      <input
                        type="datetime-local"
                        value={new Date(newDonation.date)
                          .toISOString()
                          .slice(0, 16)}
                        onChange={(e) =>
                          setNewDonation({
                            ...newDonation,
                            date: new Date(e.target.value).toISOString(),
                          })
                        }
                        className="w-full p-2 border rounded-lg dark:bg-gray-600 dark:border-gray-500 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                      />
                      <div className="flex justify-end space-x-3">
                        <button
                          onClick={() => setIsCreating(false)}
                          className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
                        >
                          Cancelar
                        </button>
                        <button
                          onClick={handleCreateDonation}
                          className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors duration-200"
                        >
                          Crear Donación
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : activeTab === 'messages' ? (
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead>
                      <tr className="bg-gray-50 dark:bg-gray-700">
                        <th
                          onClick={() => handleSort('type')}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        >
                          Tipo {getSortIndicator('type')}
                        </th>
                        <th
                          onClick={() => handleSort('message')}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        >
                          Mensaje {getSortIndicator('message')}
                        </th>
                        <th
                          onClick={() => handleSort('date')}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        >
                          Fecha {getSortIndicator('date')}
                        </th>
                        <th
                          onClick={() => handleSort('resolved')}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        >
                          Estado {getSortIndicator('resolved')}
                        </th>
                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                      {sortedMessages.map((message) => (
                        <tr
                          key={message.id}
                          className="hover:bg-gray-50 dark:hover:bg-gray-700"
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="text-gray-900 dark:text-white">
                              {message.type}
                            </span>
                          </td>
                          <td className="px-6 py-4">
                            <span className="text-gray-500 dark:text-gray-400">
                              {message.message}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="text-gray-500 dark:text-gray-400">
                              {new Date(message.date).toLocaleDateString()}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                message.resolved
                                  ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                                  : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
                              }`}
                            >
                              {message.resolved ? 'Resuelto' : 'Pendiente'}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-center">
                            <div className="flex justify-center space-x-2">
                              <Tooltip
                                content={
                                  message.resolved
                                    ? 'Marcar como Pendiente'
                                    : 'Marcar como Resuelto'
                                }
                              >
                                <button
                                  onClick={() =>
                                    handleResolveMessage(
                                      message.id,
                                      !message.resolved
                                    )
                                  }
                                  className={`inline-flex items-center px-3 py-1 rounded-md text-sm font-medium ${
                                    message.resolved
                                      ? 'bg-yellow-100 text-yellow-800 hover:bg-yellow-200 dark:bg-yellow-900 dark:text-yellow-200'
                                      : 'bg-green-100 text-green-800 hover:bg-green-200 dark:bg-green-900 dark:text-green-200'
                                  }`}
                                >
                                  <Check className="w-4 h-4 mr-1" />
                                  {message.resolved ? 'Pendiente' : 'Resuelto'}
                                </button>
                              </Tooltip>
                              {/* Botón de Eliminar */}
                              <Tooltip content="Eliminar">
                                <button
                                  onClick={() =>
                                    handleMessageDelete(message.id)
                                  }
                                  className="inline-flex items-center px-3 py-1 rounded-md text-sm font-medium bg-red-100 text-red-800 hover:bg-red-200 dark:bg-red-900 dark:text-red-200"
                                >
                                  <Trash2 className="w-4 h-4 mr-1" />
                                  Eliminar
                                </button>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                      {sortedMessages.length === 0 && (
                        <tr>
                          <td
                            colSpan={5}
                            className="px-6 py-4 text-center text-gray-500 dark:text-gray-400"
                          >
                            No se han encontrado mensajes.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : activeTab === 'users' ? (
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead>
                      <tr className="bg-gray-50 dark:bg-gray-700">
                        <th
                          onClick={() => handleSort('name')}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        >
                          Nombre {getSortIndicator('name')}
                        </th>
                        <th
                          onClick={() => handleSort('email')}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        >
                          Correo {getSortIndicator('email')}
                        </th>
                        <th
                          onClick={() => handleSort('role')}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        >
                          Rol {getSortIndicator('role')}
                        </th>
                        <th
                          onClick={() => handleSort('totalDonated')}
                          className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        >
                          Donado {getSortIndicator('totalDonated')}
                        </th>
                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                      {sortedUsers.map((user) => (
                        <tr
                          key={user.id}
                          className="hover:bg-gray-50 dark:hover:bg-gray-700"
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            {editingUser === user.id ? (
                              <input
                                type="text"
                                defaultValue={user.name}
                                className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                onBlur={(e) =>
                                  handleUserEdit(user.id, {
                                    name: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <span className="text-gray-900 dark:text-white">
                                {user.name}
                              </span>
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="text-gray-500 dark:text-gray-400">
                              {user.email}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {editingUser === user.id ? (
                              <select
                                defaultValue={user.role || 'user'}
                                className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                onChange={(e) =>
                                  handleUserEdit(user.id, {
                                    role: e.target.value,
                                  })
                                }
                              >
                                <option value="user">Usuario</option>
                                <option value="admin">Administrador</option>
                              </select>
                            ) : (
                              <span
                                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                  user.role === 'admin'
                                    ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
                                    : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'
                                }`}
                              >
                                {user.role || 'Usuario'}
                              </span>
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right">
                            {editingUser === user.id ? (
                              <input
                                type="number"
                                defaultValue={user.totalDonated}
                                className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                onBlur={(e) =>
                                  handleUserEdit(user.id, {
                                    totalDonated: parseFloat(e.target.value),
                                  })
                                }
                              />
                            ) : (
                              <span className="text-gray-900 dark:text-white font-medium">
                                {user.totalDonated}€
                              </span>
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-center">
                            <div className="flex justify-center space-x-3">
                              {editingUser === user.id ? (
                                <>
                                  <Tooltip content="Cancelar">
                                    <button
                                      onClick={() => setEditingUser(null)}
                                      className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 focus:outline-none"
                                    >
                                      <X size={20} />
                                    </button>
                                  </Tooltip>
                                  <Tooltip content="Guardar">
                                    <button
                                      onClick={() =>
                                        handleUserEdit(user.id, {})
                                      }
                                      className="text-green-500 hover:text-green-700 focus:outline-none"
                                    >
                                      <Save size={20} />
                                    </button>
                                  </Tooltip>
                                </>
                              ) : (
                                <>
                                  <Tooltip content="Editar">
                                    <button
                                      onClick={() => setEditingUser(user.id)}
                                      className="text-blue-500 hover:text-blue-700 focus:outline-none"
                                    >
                                      <Edit size={20} />
                                    </button>
                                  </Tooltip>
                                  <Tooltip content="Eliminar">
                                    <button
                                      onClick={() => handleUserDelete(user.id)}
                                      className="text-red-500 hover:text-red-700 focus:outline-none"
                                    >
                                      <Trash2 size={20} />
                                    </button>
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                      {sortedUsers.length === 0 && (
                        <tr>
                          <td
                            colSpan={5}
                            className="px-6 py-4 text-center text-gray-500 dark:text-gray-400"
                          >
                            No se han encontrado usuarios.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : activeTab === 'donations' ? (
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead>
                      <tr className="bg-gray-50 dark:bg-gray-700">
                        <th
                          onClick={() => handleSort('name')}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        >
                          Nombre {getSortIndicator('name')}
                        </th>
                        <th
                          onClick={() => handleSort('amount')}
                          className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        >
                          Donado {getSortIndicator('amount')}
                        </th>
                        <th
                          onClick={() => handleSort('date')}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        >
                          Fecha {getSortIndicator('date')}
                        </th>
                        <th
                          onClick={() => handleSort('message')}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                        >
                          Mensaje {getSortIndicator('message')}
                        </th>
                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                      {sortedDonations.map((donation) => (
                        <tr
                          key={donation.id}
                          className="hover:bg-gray-50 dark:hover:bg-gray-700"
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            {editingDonation === donation.id ? (
                              <input
                                type="text"
                                defaultValue={donation.name}
                                className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                onBlur={(e) =>
                                  handleDonationEdit(donation.id, {
                                    name: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <span className="text-gray-900 dark:text-white">
                                {donation.name}
                              </span>
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right">
                            {editingDonation === donation.id ? (
                              <input
                                type="number"
                                defaultValue={donation.amount}
                                className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                onBlur={(e) =>
                                  handleDonationEdit(donation.id, {
                                    amount: parseFloat(e.target.value),
                                  })
                                }
                              />
                            ) : (
                              <span className="text-gray-900 dark:text-white font-medium">
                                {donation.amount}€
                              </span>
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {editingDonation === donation.id ? (
                              <input
                                type="datetime-local"
                                defaultValue={new Date(donation.date)
                                  .toISOString()
                                  .slice(0, 16)}
                                className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                onBlur={(e) =>
                                  handleDonationEdit(donation.id, {
                                    date: new Date(
                                      e.target.value
                                    ).toISOString(),
                                  })
                                }
                              />
                            ) : (
                              <span className="text-gray-500 dark:text-gray-400">
                                {new Date(donation.date).toLocaleDateString()}
                              </span>
                            )}
                          </td>
                          <td className="px-6 py-4">
                            {editingDonation === donation.id ? (
                              <input
                                type="text"
                                defaultValue={donation.message}
                                className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                onBlur={(e) =>
                                  handleDonationEdit(donation.id, {
                                    message: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <span className="text-gray-500 dark:text-gray-400">
                                {donation.message}
                              </span>
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-center">
                            <div className="flex justify-center space-x-3">
                              {editingDonation === donation.id ? (
                                <>
                                  <Tooltip content="Cancelar">
                                    <button
                                      onClick={() => setEditingDonation(null)}
                                      className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 focus:outline-none"
                                    >
                                      <X size={20} />
                                    </button>
                                  </Tooltip>
                                  <Tooltip content="Guardar">
                                    <button
                                      onClick={() =>
                                        handleDonationEdit(donation.id, {})
                                      }
                                      className="text-green-500 hover:text-green-700 focus:outline-none"
                                    >
                                      <Save size={20} />
                                    </button>
                                  </Tooltip>
                                </>
                              ) : (
                                <>
                                  <Tooltip content="Editar">
                                    <button
                                      onClick={() =>
                                        setEditingDonation(donation.id)
                                      }
                                      className="text-blue-500 hover:text-blue-700 focus:outline-none"
                                    >
                                      <Edit size={20} />
                                    </button>
                                  </Tooltip>
                                  <Tooltip content="Eliminar">
                                    <button
                                      onClick={() =>
                                        handleDonationDelete(donation.id)
                                      }
                                      className="text-red-500 hover:text-red-700 focus:outline-none"
                                    >
                                      <Trash2 size={20} />
                                    </button>
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                      {sortedDonations.length === 0 && (
                        <tr>
                          <td
                            colSpan={5}
                            className="px-6 py-4 text-center text-gray-500 dark:text-gray-400"
                          >
                            No se han encontrado donaciones.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </motion.div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );

  return isOpen ? createPortal(modalContent, document.body) : null;
};

export default AdminModal;