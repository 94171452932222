import React, { createContext, useState, useEffect, useContext } from 'react';
import netlifyIdentity from 'netlify-identity-widget';

type User = {
  id: string;
  email: string;
  user_metadata: {
    full_name: string;
    isAdmin?: boolean;
  };
  created_at: string;
};

type AuthContextType = {
  user: User | null;
  login: () => void;
  logout: () => void;
  authReady: boolean;
  updateUserProfile: (fullName: string) => Promise<void>;
  changePassword: (newPassword: string) => Promise<void>;
  getToken: () => Promise<string | null>;
  checkUserNameExists: (userName: string) => Promise<boolean>;
  isAdmin: boolean;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [authReady, setAuthReady] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const checkAdminStatus = async (token: string) => {
    try {
      const response = await fetch('/.netlify/functions/check-admin-role', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Error en verificar l\'estat d\'administrador');
      }
      
      const { isAdmin: adminStatus } = await response.json();
      setIsAdmin(adminStatus);
    } catch (error) {
      console.error('Error en verificar l\'estat d\'administrador:', error);
      setIsAdmin(false);
    }
  };

  const refreshUserProfile = async (currentUser: any) => {
    try {
      const token = await currentUser.jwt();
      const response = await fetch('/.netlify/functions/get-user-profile', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-store, no-cache, must-revalidate',
          'Pragma': 'no-cache'
        }
      });

      if (!response.ok) {
        // Verificar si la respuesta es HTML (error de servidor)
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('text/html')) {
          throw new Error('Error de servidor al refrescar l\'usuari');
        }
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const updatedUser = await response.json();
      setUser(updatedUser);
      localStorage.setItem('currentUser', JSON.stringify(updatedUser));
      await checkAdminStatus(token);
    } catch (error) {
      console.error('Error en refrescar l\'usuari:', error);
      return null;
    }
  };

  useEffect(() => {
    netlifyIdentity.on('login', async (user) => {
      const currentUser = user as User;
      setUser(currentUser);
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      
      const token = await netlifyIdentity.currentUser()?.jwt();
      if (token) {
        await checkAdminStatus(token);
      }
      
      netlifyIdentity.close();
    });

    netlifyIdentity.on('logout', () => {
      setUser(null);
      setIsAdmin(false);
      localStorage.removeItem('currentUser');
    });

    netlifyIdentity.on('init', async (user) => {
      if (user) {
        const currentUser = user as User;
        setUser(currentUser);
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        
        const token = await netlifyIdentity.currentUser()?.jwt();
        if (token) {
          await checkAdminStatus(token);
        }
      }
      setAuthReady(true);
    });

    const refreshInterval = setInterval(async () => {
      const currentUser = netlifyIdentity.currentUser();
      if (currentUser) {
        await refreshUserProfile(currentUser);
      }
    }, 300000); // 5 minuts

    netlifyIdentity.init({
      locale: 'es'
    });

    return () => {
      netlifyIdentity.off('login');
      netlifyIdentity.off('logout');
      netlifyIdentity.off('init');
      clearInterval(refreshInterval);
    };
  }, []);

  const login = () => {
    netlifyIdentity.open('login');
  };

  const logout = () => {
    netlifyIdentity.logout();
  };

  const getToken = async (): Promise<string | null> => {
    const currentUser = netlifyIdentity.currentUser();
    if (!currentUser) {
      return null;
    }
    return currentUser.jwt();
  };

  const updateUserProfile = async (fullName: string) => {
    try {
      const currentUser = netlifyIdentity.currentUser();
      if (!currentUser) {
        throw new Error('No hi ha un usuari autenticat');
      }
      
      const token = await currentUser.jwt();
      
      const response = await fetch('/.netlify/functions/update-user-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Cache-Control': 'no-store, no-cache, must-revalidate',
          'Pragma': 'no-cache'
        },
        body: JSON.stringify({ fullName }),
      });

      if (!response.ok) {
        const errorData = await response.text();
        console.error('Error en actualitzar el perfil de l\'usuari:', response.status, errorData);
        throw new Error(`Error en actualitzar el perfil de l'usuari: ${response.status} ${errorData}`);
      }

      const updatedUser = await response.json();
      setUser(updatedUser);
      localStorage.setItem('currentUser', JSON.stringify(updatedUser));
    } catch (error) {
      console.error('Error en actualitzar el perfil de l\'usuari:', error);
      throw error;
    }
  };

  const changePassword = async (newPassword: string) => {
    try {
      const currentUser = netlifyIdentity.currentUser();
      if (!currentUser) {
        throw new Error('No hi ha un usuari autenticat');
      }
      await currentUser.update({ password: newPassword });
    } catch (error) {
      console.error('Error en canviar la contrasenya:', error);
      throw error;
    }
  };

  const checkUserNameExists = async (userName: string): Promise<boolean> => {
    try {
      const token = await getToken();
      if (!token) {
        throw new Error('No hi ha un token d\'autenticació disponible');
      }

      const response = await fetch('/.netlify/functions/check-username', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Cache-Control': 'no-store, no-cache, must-revalidate',
          'Pragma': 'no-cache'
        },
        body: JSON.stringify({ userName }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Resposta del servidor:', errorText);
        throw new Error(`Error en verificar el nom d'usuari: ${response.status} ${response.statusText}`);
      }

      const { exists } = await response.json();
      return exists;
    } catch (error) {
      console.error('Error en verificar el nom d\'usuari:', error);
      throw error;
    }
  };

  const context: AuthContextType = { 
    user, 
    login, 
    logout, 
    authReady, 
    updateUserProfile, 
    changePassword,
    getToken,
    checkUserNameExists,
    isAdmin
  };

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth s\'ha d\'utilitzar dins d\'un AuthContextProvider');
  }
  return context;
};