import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { DonationsTable } from './components/DonationsTable';
import DarkModeToggle from './components/DarkModeToggle';
import Footer from './components/Footer';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from './context/AuthContext';
import confetti from 'canvas-confetti';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const DonatePage: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [donationAmount, setDonationAmount] = useState('');
  const [donationMessage, setDonationMessage] = useState('');
  const [donorName, setDonorName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const { user, login } = useAuth();

  const MAX_NAME_LENGTH = 20;
  const MAX_MESSAGE_LENGTH = 500;

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => setIsLoaded(true), 100);
    if (user) {
      setDonorName(
        user.user_metadata.full_name || user.email || "Escull un nom d'usuari"
      );
    }
  }, [user]);

  const handleDonation = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (
      !donationAmount ||
      isNaN(parseFloat(donationAmount)) ||
      parseFloat(donationAmount) <= 0
    ) {
      setError('Si us plau, introdueix una quantitat vàlida.');
      return;
    }

    if (!donorName.trim()) {
      setError('Si us plau, introdueix el teu nom.');
      return;
    }

    if (donorName.length > MAX_NAME_LENGTH) {
      setError(`El nom no pot tenir més de ${MAX_NAME_LENGTH} caràcters.`);
      return;
    }

    if (donationMessage.length > MAX_MESSAGE_LENGTH) {
      setError(
        `El missatge no pot tenir més de ${MAX_MESSAGE_LENGTH} caràcters.`
      );
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(
        `${
          import.meta.env.VITE_APP_URL
        }/.netlify/functions/create-checkout-session`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: parseFloat(donationAmount),
            message: donationMessage,
            name: donorName,
            userId: user ? user.id : null,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.text();
        console.error('Server response:', errorData);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const session = await response.json();

      if (session.id) {
        const stripe = await stripePromise;
        if (stripe) {
          confetti({
            particleCount: 150,
            spread: 100,
            origin: { y: 0.6 },
            scalar: 1.5,
          });

          setTimeout(async () => {
            const { error } = await stripe.redirectToCheckout({
              sessionId: session.id,
            });

            if (error) {
              setError(
                error.message || 'Hi ha hagut un error en processar la donació.'
              );
            }
          }, 1250);
        } else {
          setError("No s'ha pogut carregar Stripe.");
        }
      } else {
        throw new Error("No s'ha rebut un ID de sessió vàlid");
      }
    } catch (error) {
      console.error('Error:', error);
      setError(
        'Hi ha hagut un error en processar la donació. Si us plau, torna-ho a provar més tard.'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLogin = (e: React.MouseEvent) => {
    e.preventDefault();
    login();
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    if (newName.length <= MAX_NAME_LENGTH) {
      setDonorName(newName);
    }
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = e.target.value;
    if (newMessage.length <= MAX_MESSAGE_LENGTH) {
      setDonationMessage(newMessage);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    setDonationAmount(value);
  };

  return (
    <div
      className={`min-h-screen flex flex-col bg-texture bg-white dark:bg-gray-900 transition-colors duration-300 ${isLoaded}`}
    >
      <div className="flex-grow px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
        <div className="max-w-7xl mx-auto">
          <header
            className={`flex justify-between items-center mb-6 sm:mb-8 transition-all duration-300 ${
              isLoaded
                ? 'translate-y-0 opacity-100'
                : '-translate-y-10 opacity-0'
            }`}
          >
            <Link to="/" className="group">
              <div className="icon-bg">
                <ArrowLeft className="text-white w-1/2 h-1/2" />
              </div>
            </Link>
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-red-500 absolute left-1/2 transform -translate-x-1/2">
              Donar
            </h1>
            <DarkModeToggle />
          </header>

          <div className="space-y-8">
            {/* Tarjeta de Donación */}
            <div
              className={`bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden transition-all duration-300 ${
                isLoaded
                  ? 'translate-y-0 opacity-100'
                  : 'translate-y-10 opacity-0'
              }`}
            >
              <div className="p-6">
                <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 text-gray-800 dark:text-white text-center">
                  La teva donació marca la diferència!
                </h2>
                <p className="text-gray-600 dark:text-gray-300 mb-6 text-center">
                  Gràcies a la teva generositat, podem continuar oferint
                  minijocs gratuïts i millorant constantment l'experiència de
                  tots els jugadors de la nostra comunitat. Cada donació és una
                  empenta per seguir creant, innovant i oferint moments
                  d'entreteniment a tothom qui vulgui gaudir dels nostres
                  minijocs. Això manté viva la comunitat de{' '}
                  <strong>minijocs.cat</strong>, un espai que és de tots.
                </p>
                <p className="text-gray-600 dark:text-gray-300 mb-6 text-center">
                  Amb el teu suport, tenim l'oportunitat no només de crear nous
                  minijocs i perfeccionar els actuals, sinó també d'organitzar
                  esdeveniments especials, millorar constantment la plataforma i
                  fer-la accessible i divertida per a tothom, sense barreres.
                  Gràcies a tu, estem construint més que una simple pàgina de
                  minijocs; estem creant un espai on tothom se senti part d'una
                  comunitat catalana vibrant i acollidora.
                </p>
                <p className="text-gray-600 dark:text-gray-300 mb-6 text-center">
                  La teva donació, gran o petita, té un impacte real i durador
                  que contribueix a mantenir aquesta aventura en marxa i a
                  fer-la créixer. Junts, podem anar més enllà i fer d'aquest
                  lloc un lloc únic per a jugadors de totes les edats. Gràcies
                  per sumar-te a aquesta aventura i per confiar en nosaltres!
                </p>

                <form onSubmit={handleDonation}>
                  {!user && (
                    <div className="mb-6 p-4 bg-yellow-100 dark:bg-yellow-900 rounded-lg">
                      <div className="text-yellow-800 dark:text-yellow-200 text-center">
                        Si fas una donació sense iniciar sessió, la teva donació
                        es processarà, però no es guardarà als Tops de
                        donacions.
                      </div>
                      <div className="text-yellow-800 dark:text-yellow-200 text-center mt-2">
                        <button
                          onClick={handleLogin}
                          className="ml-2 underline"
                        >
                          <strong>Inicia sessió</strong>
                        </button>{' '}
                        per aparèixer als Tops.
                      </div>
                    </div>
                  )}

                  {!user && (
                    <div className="mt-4">
                      <input
                        type="text"
                        value={donorName}
                        onChange={handleNameChange}
                        placeholder="El teu nom"
                        className="w-full p-3 border-2 border-yellow-500 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                        required
                        maxLength={MAX_NAME_LENGTH}
                      />
                      <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                        {donorName.length}/{MAX_NAME_LENGTH} caràcters
                      </p>
                    </div>
                  )}

                  {user && (
                    <div className="mt-4">
                      <div className="w-full p-3 border-2 border-yellow-500 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg">
                        {donorName}
                      </div>
                      <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                        Per canviar el nom,{' '}
                        <Link
                          to="/usuari"
                          className="text-blue-500 hover:text-blue-600 underline"
                        >
                          vés al teu perfil d'usuari
                        </Link>
                        .
                      </p>
                    </div>
                  )}

                  <h3 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white text-center mt-6">
                    Tria la quantitat
                  </h3>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-4">
                    {[0.5, 1, 2, 5, 10, 20].map((amount) => (
                      <button
                        key={amount}
                        type="button"
                        onClick={() => setDonationAmount(amount.toString())}
                        className={`py-3 px-6 rounded-lg text-lg font-semibold transform transition-all duration-300 ${
                          donationAmount === amount.toString()
                            ? 'bg-gradient-to-r from-yellow-500 to-red-500 text-white'
                            : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white'
                        } hover:scale-105 hover:bg-gradient-to-r hover:from-yellow-400 hover:to-red-400`}
                      >
                        {amount}€
                      </button>
                    ))}
                  </div>

                  <div className="mt-4">
                    <input
                      type="text"
                      value={donationAmount ? `${donationAmount}€` : ''}
                      onChange={handleAmountChange}
                      placeholder="Altra quantitat"
                      className="w-full p-3 border-2 border-yellow-500 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                      required
                    />
                  </div>

                  <textarea
                    value={donationMessage}
                    onChange={handleMessageChange}
                    placeholder="Deixa un missatge (opcional)"
                    className="mt-4 w-full p-3 border-2 border-yellow-500 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                    rows={3}
                    maxLength={MAX_MESSAGE_LENGTH}
                  />
                  <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    {donationMessage.length}/{MAX_MESSAGE_LENGTH} caràcters
                  </p>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="mt-6 w-full bg-gradient-to-r from-yellow-500 to-red-500 hover:from-yellow-600 hover:to-red-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300 flex items-center justify-center text-xl disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isSubmitting ? 'Processant...' : 'Fer la donació'}
                  </button>

                  {error && <p className="text-red-500 mt-2">{error}</p>}
                </form>
              </div>
            </div>

            {/* Tarjeta de Donaciones */}
            <div
              className={`bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden transition-all duration-300 ${
                isLoaded
                  ? 'translate-y-0 opacity-100'
                  : 'translate-y-10 opacity-0'
              }`}
            >
              <div className="p-6 min-h-[370px]">
                <DonationsTable />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DonatePage;
