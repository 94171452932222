import React, { useState } from 'react';
import { ShieldCheck } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import AdminModal from './AdminModal';

const AdminButton: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isAdmin } = useAuth();

  if (!isAdmin) return null;

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="icon-bg"
        aria-label="Open Admin Panel"
      >
        <ShieldCheck className="text-white w-1/2 h-1/2" />
      </button>
      {isModalOpen && (
        <AdminModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default AdminButton;
